import React from 'react';
import { Link } from 'react-router-dom';
import schoolsData from '../../data/schoolsData.json';
import BgService from '../../assets/img/service/service-bg.jpg';
import university from '../../assets/img/icon/university.png';

const ServiceFour = () => {

    return (
        <section className="service-area service-area2 pt-115 pb-100" style={{ backgroundImage:`url(${BgService})`}}>
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-xxl-6 col-lg-6">
                        <div className="kservice-text mb-50 aos-init aos-animate" data-aos="fade-right" data-aos-duration="1000">
                            <h5 className="kservice-text-subtitle mb-15">Schools Offering</h5>
                            <h2 className="kservice-text-title mb-40">Professional Certificate Programs</h2>
                        </div>
                    </div>
                    <div className="col-xxl-5 col-lg-6">
                        <div className="kservice-text mb-50 aos-init aos-animate" data-aos="fade-right" data-aos-duration="1000">
                            <p className="mb-45 mt-35 mt-md-0 mt-xs-0">Embark on a transformative educational journey with our schools offering Professional Certificate Programs, empowering you to unlock the potential of AI across diverse disciplines, from General Artificial Intelligence to specialized domains like AI in Business & Finance, AI in Natural Sciences, etc.</p>
                        </div>
                    </div>
                </div>

                <div className="row custom-mar-20" data-aos="fade-down" data-aos-duration="1000">
                    {schoolsData.map(school => (
                        <div key={school.id} className="container-fluid col-xxl-3 col-xl-3 col-lg-3 col-sm-6 custom-pad-20">
                            <div className="kservice text-center mb-20">
                                <div className="schools-img">
                                    <img src={university} style={{height: 70}} alt="prog-img"/>
                                </div>
                                <div className="kservice-content">
                                    <h5 className="kservice-content-title"><Link to={`/schools${school.schoolLink}`}><a>{school.schoolName}<br/></a></Link></h5>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default ServiceFour;