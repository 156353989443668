function formatNumber(number) {
    return new Intl.NumberFormat('en-US').format(number);
}

const price = [
    {
        id: 3,
        type: "Certificate Package",
        rate: 1506,
        description: "A comprehensive professional certificate package, starting from Python programming to specialized AI program you selected.",
        item1: 'Python for AI',
        item2: "Machine Learning",
        item3: "Deep Learning or relevant program",
        item4: "AI Ethics and Governance",
        item5: "Full Job Support or AiStartUp support",
        item6: "3-Month Internship",
        supportClass: "danger",
        supportSymbol: "close",
        ribbon: "cheap",
        planButtonClassname: "primary",
        btntxt: "Choose Plan",
        delrateYear: "2,499"
    },
    {
        id: 4,
        type: "Career Package",
        rate: 4999,
        description: "This plan includes the Certificate Package, enhanced with additional career development support.",
        item1: 'Python for AI',
        item2: "Machine Learning",
        item3: "Deep Learning or relevant program",
        item4: "AI Ethics and Governance",
        item5: "Full Job Support or AiStartUp support",
        item6: "3-Month Internship",
        supportClass: "success",
        supportSymbol: "checkbox",
        ribbon: "popular",
        planButtonClassname: "primary",
        btntxt: "Choose Plan",
        delrateYear: "6,999"
    },
    {
        id: 5,
        type: "Career Package",
        rate: 2499,
        description: "This plan includes the same Career Package, partially subsidized by our collaborating organizations.",
        item1: 'Python for AI',
        item2: "Machine Learning",
        item3: "Deep Learning or relevant program",
        item4: "AI Ethics and Governance",
        item5: "Full Job Support or AiStartUp support",
        item6: "3-Month Internship",
        supportClass: "success",
        supportSymbol: "checkbox",
        ribbon: "scholar",
        planButtonClassname: "primary",
        btntxt: "Apply for Scholarship",
        delrateYear: "2,999"
    },
];

// Format the 'rate' field in the pricing data
const pricing = price.map(item => ({
    ...item,
    rate: formatNumber(item.rate)
}));

export default pricing;