import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ProgramBanner from './ProgramBanner';
import ProgramFacts from './ProgramFacts';
import Prerequisite  from './Prerequisite';
import WhatYouLearn from './WhatYouLearn';
// import Syllabus from './Syllabus';
import FeesSection from './FeesSection';
import SinglePricing from './SinglePricing';
// import Testimonial from './TestimonialsSection';
import RelatedCourseSlider from './RelatedCourseSlider';
import schoolsData from '../../data/schoolsData.json';



const CourseDetails = () => {

    const { schoolLink, departmentLink, programLink} = useParams();
    const [schoolDetails, setSchoolDetails] = useState(null);
    const [departmentDetails, setDepartmentDetails] = useState(null);
    const [programDetails, setProgramDetails] = useState(null);
    


    useEffect(() => {
        const school = schoolsData.find(s => s.schoolLink ===  `/${schoolLink}`);
        setSchoolDetails(school);
        const departments = school.departments
        const department = departments?.find(d => d.departmentLink ===  `/${departmentLink}`);
        setDepartmentDetails(department);
        const programs = department?.allprograms
        const program = programs?.find(p => p.titleString ===  `/${programLink}`);
        setProgramDetails(program);
    }, [schoolLink, departmentLink, programLink]);


    if (!schoolDetails || !programDetails) {
        return <div>Loading...</div>;
    }

    const invalidTitles = [
        "Python for AI",
        "AI Ethics and Governance: Shaping the Future",
        "Machine Learning with PyTorch",
        "Machine Learning with TensorFlow"
    ];

    const shouldUseSinglePricing = invalidTitles.includes(programDetails.programTitle);

 
    return <main>
        <ProgramBanner program={programDetails} />
        <ProgramFacts department={departmentDetails} programLink={programLink} />
        <WhatYouLearn department={departmentDetails} programLink={programLink} />
        <Prerequisite program={programDetails} />
        {/* <Syllabus/> */}
        <div className='pricing-section'>
            {shouldUseSinglePricing ? (
                <SinglePricing program={programDetails} />
            ) : (
                <FeesSection program={programDetails} />
            )}
        </div>
        {/* <div className='mt--30'>
            <Testimonial />
        </div> */}
        <RelatedCourseSlider 
            school={schoolDetails} 
            departmentDetails={departmentDetails}
            program={programDetails} 
        />
    </main>;
}

export default CourseDetails;