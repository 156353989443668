import React from 'react';
import ResearchBanner from './ReasearchBanner'
import FundingBody from './FundingBody';
import ResearchCta from './ResearchCta';
import ResearchCategory from './ResearchCategory';



const ContactMain = () => {
        
    return (
        <React.Fragment>
            {/* banner-start */}
            <ResearchBanner />
            {/* banner-end */}

            {/* contact-start */}
            <ResearchCategory />
            {/* contact-end */}

            {/* map-start */}
            <FundingBody />
            {/* map-end */}

            {/* cta-start */}
            <ResearchCta />
            {/* cta-end */}

        </React.Fragment>
    );
    
}

export default ContactMain;