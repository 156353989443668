import React from 'react';
import FooterBottom from './FooterBottom';
import { Link } from 'react-router-dom';

import BgImage from '../../../assets/img/bg/footer-bg.jpg';
import Logo from "../../../assets/img/logo/logo-white.png";

const Footer = () => {

    return (
      <footer style={{ backgroundImage:`url(${BgImage})`}} className="pt-95 position-relative">
         <div className="common-shape-wrapper wow slideInRight animated" data-wow-delay="0ms" data-wow-duration="1500ms">
            <div className="common-shape-inner wow slideInRight animated" data-wow-delay="0ms" data-wow-duration="1500ms"></div>
         </div>
         <div className="footer-space pb-60">
            <div className="container">
               <div className="row">
                     <div className="col-lg-4 col-sm-6">
                        <div className="widget mb-30 sides-padding">
                           <div className="footer-logo mb-25" >
                                 <Link to="/"><span><img src={Logo} className="img-fluid h-75" alt="footer-logo"/></span></Link>
                           </div>
                           {/* <p className="mb-20 pr-35">Metaversity AI offers specialized AI Masters Degrees exclusively for bachelor's degree holders in diverse disciplines such as Cybersecurity, Medicine, Chemistry, and more, along with flexible AI Professional Certificate courses that cater to enthusiasts and professionals alike, fostering a diverse and collaborative learning environment.</p> */}
                           <p className="mb-20 pr-35">Metaversity of Artificial Intelligence offers specialized AI Professional Certificate Programs that are for bachelor's degree holders in diverse disciplines such as Cybersecurity, Medicine, Chemistry, etc., along with general AI Professional Certificate courses that cater to enthusiasts and professionals alike, fostering a diverse and collaborative learning environment.</p>
                        </div>
                     </div>
                     <div className="col-lg-2 col-sm-6">
                        <div className="widget mb-30">
                           <h4 className="footer-title ps-30 mb-35">Links</h4>
                           <ul>
                                 <li><Link to="/schools"><span>Schools</span></Link></li>
                                 <li><Link to="/research"><span>Research</span></Link></li>
                                 <li><Link to="/education-quality-assurance-standards"><span>EQAS</span></Link></li>
                                 <li><Link to="/journal-of-aii"><span>Journal of AI Spectra</span></Link></li>
                                 <li><Link to="/admissions-office"><span>Contact</span></Link></li>
                                 <li><Link to="/sitemap"><span>Site Map</span></Link></li>
                           </ul>
                        </div>
                     </div>
                     <div className="col-lg-3 col-sm-6">
                        <div className="widget widget-contact mb-30">
                           <h4 className="footer-title mb-35">Contact</h4>
                           <ul>
                                 <li className="pb-10">1 Wagener Place, Mt Albert,
                                    <br/> Auckland 1025, New Zealand</li>
                                 <li><i className="bx bx-mail-send fs-4 text-primary mt-2 pe-2"/><a href="mailto:consult@metaversityai.ai" className="mb-1 align-self-start">info@metaversityai.ai</a></li>
                                 <li><i className="bx bx-phone fs-4 text-primary mt-2 pe-2" /><a href="tel:64 22410 6020" className="mb-1 align-self-start">+64 22 410 6020</a></li>
                           </ul>
                        </div>
                     </div>
                     <div className="col-lg-3 col-sm-6">
                        <div className="widget mb-30 sides-padding">
                           <h4 className="widget-title mb-30">Newsletter</h4>
                           <p className="mb-20">Subscribe to Our Newsletter for Updates on Artificial Intelligence</p>
                           <div className="widget-newsletter">
                                 <form action="#">
                                    <input type="email" placeholder="Email Address"/>
                                    <button type="submit">Send</button>
                                 </form>
                           </div>
                           <div className="footer-social mt-30">
                              <a href="https://www.linkedin.com/company/metaversity-of-artificial-intelligence/" target="blank" rel="noopener"><i className="fab fa-linkedin"></i></a>
                              <a href="https://twitter.com/Metaversity_AI" target="blank" rel="noopener"><i className="fab fa-twitter"></i></a>
                              <a href="https://www.facebook.com/profile.php?id=61559307292748" target="blank" rel="noopener"><i className="fab fa-facebook-f"></i></a>
                              {/* <a href="#" target="blank" rel="noopener"><i className="fab fa-pinterest-p"></i></a> */}
                              <a href="https://www.instagram.com/meatversityai/" target="blank" rel="noopener"><i className="fab fa-instagram"></i></a>
                           </div>
                        </div>
                     </div>
               </div>
            </div>
         </div>
         <FooterBottom />
      </footer>
    );
}

export default Footer;