import React from "react";
import { Link, NavLink } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";


const TermsCondition = () => {

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="justify-content-center">
            <Col lg={10}>
              <Card>
                <div className="bg-soft-info mt-5 position-relative">
                  <CardBody className="card-body mt-5 p-5">
                    <div className="text-center">
                      <h3>Privacy Policy</h3>
                      <p className="mb-0 text-muted">
                        Last update: 18 April, 2024
                      </p>
                    </div>
                  </CardBody>
                </div>
                <CardBody className="p-4">
                  <div>
                    <meta httpEquiv="content-type" content="text/html; charset=utf-8" />
                    <title />
                    <meta name="AppVersion" content={16.0000} />
                    <style type="text/css" dangerouslySetInnerHTML={{__html: "\n\t\t@page { size: 8.5in 11in; margin: 1in }\n\t\tp { line-height: 115%; text-align: left; orphans: 2; widows: 2; margin-bottom: 0.1in; direction: ltr; background: transparent }\n\t\th1 { color: #2e74b5; line-height: 108%; text-align: left; page-break-inside: avoid; orphans: 2; widows: 2; margin-bottom: 0in; direction: ltr; background: transparent; page-break-after: avoid }\n\t\th1.western { font-family: \"Calibri Light\", serif; font-size: 16pt }\n\t\th1.cjk { font-family: ; font-size: 16pt }\n\t\th1.ctl { font-family: ; font-size: 16pt }\n\t" }} />
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}><br />
                    </p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}><br />
                    </p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>Metaversity AI ("the
                      Company," "we," or "our") is committed to
                      protecting your privacy and safeguarding the personal information you
                      provide while using our services. This Privacy Policy outlines how we
                      collect, use, disclose, and protect your personal information in
                      accordance with New Zealand and international privacy laws. By using
                      our services, you consent to the practices described in this Privacy
                      Policy.</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}><br />
                    </p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>1 Collection of
                      Personal Information</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}><br />
                    </p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>We may collect
                      personal information from you when you create an account, enroll in
                      courses, participate in discussions, submit assignments, communicate
                      with us, or otherwise interact with our platform. The types of
                      personal information we may collect include but are not limited to:</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}><br />
                    </p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>- Your name, email
                      address, and contact details.</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>- Your profile
                      information and preferences.</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>- Information
                      related to your educational background and interests.</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>- Payment and
                      billing information for course enrollments and purchases.</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>- Communications and
                      interactions you have with instructors, mentors, or other users.</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}><br />
                    </p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>We collect this
                      information for the purpose of providing and improving our services,
                      personalizing your learning experience, facilitating communication,
                      processing payments, and complying with legal obligations.</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}><br />
                    </p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>2 Use of Personal
                      Information</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}><br />
                    </p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>We may use your
                      personal information for the following purposes:</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}><br />
                    </p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>- To provide and
                      administer our services, including enrollment in courses, access to
                      course materials, and communication with instructors and other users.</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>- To personalize and
                      improve your learning experience, such as suggesting relevant courses
                      or content.</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>- To communicate
                      with you regarding your account, course updates, notifications, and
                      promotional offers.</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>- To process
                      payments, verify transactions, and prevent fraudulent activities.</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>- To conduct
                      research and analysis to enhance our services, develop new features,
                      and improve user satisfaction.</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>- To comply with
                      applicable laws, regulations, or legal processes.</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>- To protect the
                      rights, property, or safety of Metaversity AI, our users, or the
                      public.</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}><br />
                    </p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>3 Disclosure of
                      Personal Information</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}><br />
                    </p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>We may disclose your
                      personal information to the following parties:</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}><br />
                    </p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>- Instructors and
                      mentors: We may share relevant information with instructors and
                      mentors to facilitate your learning experience, provide feedback, and
                      support your educational journey.</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>- Educational
                      partners: In cases where we collaborate with educational partners to
                      deliver courses, we may share certain personal information with them
                      for administrative and instructional purposes.</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>- Service providers:
                      We may engage third-party service providers who assist us in
                      delivering our services, such as hosting platforms, payment
                      processors, customer support, and data analysis. These service
                      providers are contractually obligated to protect your personal
                      information and can only use it for the specific purposes we outline.</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>- Legal and
                      regulatory authorities: We may disclose your personal information in
                      response to lawful requests, court orders, or legal processes to
                      comply with applicable laws and regulations.</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>- With your consent:
                      We may share your personal information with other third parties if
                      you provide us with your explicit consent.</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}><br />
                    </p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>4 Data Security</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}><br />
                    </p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>We take reasonable
                      measures to protect your personal information from unauthorized
                      access, use, or disclosure. We employ administrative, technical, and
                      physical safeguards to maintain the confidentiality, integrity, and
                      availability of your data. However, no method of data transmission or
                      storage is entirely secure, and we cannot guarantee absolute
                      security.</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}><br />
                    </p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>5 Data Retention</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}><br />
                    </p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>We will retain your
                      personal information for as long as necessary to fulfill the purposes
                      outlined in this Privacy Policy, comply with legal obligations,
                      resolve disputes, and enforce our agreements. When personal
                      information is no longer needed, we will securely delete or anonymize
                      it.</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}><br />
                    </p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>6 International Data
                      Transfers</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}><br />
                    </p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>As Metaversity AI
                      operates globally, your personal information may be transferred and
                      stored in countries outside of New Zealand. These countries may have
                      different data protection laws than your own. We will ensure that
                      appropriate safeguards are in place to protect your personal
                      information when transferred to such countries.</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}><br />
                    </p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>7 Your Rights and
                      Choices</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}><br />
                    </p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>You have certain
                      rights regarding your personal information, including the right to:</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}><br />
                    </p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>- Access and request
                      a copy of your personal information held by Metaversity AI.</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>- Rectify or update
                      your personal information if it is inaccurate or incomplete.</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>- Request the
                      erasure of your personal information under certain circumstances.</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>- Restrict or object
                      to the processing of your personal information in specific
                      situations.</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>- Withdraw your
                      consent for the processing of your personal information.</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>- Receive your
                      personal information in a structured, commonly used, and
                      machine-readable format.</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}><br />
                    </p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>To exercise these
                      rights or make any inquiries regarding your personal information,
                      please contact us using the information provided in the "Contact
                      Us" section below. We will respond to your requests and
                      inquiries in accordance with applicable data protection laws.</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}><br />
                    </p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>8 Third-Party
                      Websites and Services</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}><br />
                    </p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>Our services may
                      contain links to third-party websites, applications, or services that
                      are not controlled or operated by Metaversity AI. This Privacy Policy
                      does not apply to those third-party platforms. We encourage you to
                      review the privacy policies of any third-party websites or services
                      you interact with.</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}><br />
                    </p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>9 Updates to the
                      Privacy Policy</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}><br />
                    </p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>We may update this
                      Privacy Policy from time to time to reflect changes in our practices
                      or legal requirements. We will notify you of any material changes by
                      posting the updated Privacy Policy on our website or through other
                      means of communication. We encourage you to review this Privacy
                      Policy periodically to stay informed about how we collect, use, and
                      protect your personal information.</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}><br />
                    </p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>10 Contact Us</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}><br />
                    </p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}>If you have any
                      questions, concerns, or requests regarding this Privacy Policy or the
                      privacy practices of Metaversity AI, please contact us using the
                      following information:</p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}><br />
                    </p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}><br />
                    </p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}><font size={2} style={{fontSize: '10pt'}}>Metaversity
                        of Artificial Intelligence Ltd. </font>
                    </p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}><a name="_GoBack" />
                      <font size={2} style={{fontSize: '10pt'}}>1 Wagener Place, Mt Albert,
                        Auckland 2025.</font></p>
                    <p style={{lineHeight: '108%', marginBottom: '0in'}}><font size={2} style={{fontSize: '10pt'}}>info@metaversityai.ai</font></p>
                    <p style={{lineHeight: '108%', marginBottom: '0.11in'}}><br />
                      <br />
                    </p>
                  </div>
    
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TermsCondition;
