import React from 'react';
import ContactBanner from './ContactBanner'
//import Map from '../Home/MapSection';
import CtaTwo from './ContactCta';
import ContactSection from './ContactSection';



const ContactMain = () => {
        
    return (
        <React.Fragment>
            {/* banner-start */}
            <ContactBanner />
            {/* banner-end */}

            {/* contact-start */}
            <ContactSection />
            {/* contact-end */}

            {/* cta-start */}
            <CtaTwo />
            {/* cta-end */}

            {/* map-start */}
            {/* <Map /> */}
            {/* map-end */}
        </React.Fragment>
    );
    
}

export default ContactMain;