import React from 'react';
import { Link } from 'react-router-dom';

const Cta = () => {

    return (
        <section className="cta-area mb--40 z-index" data-aos="fade-up">
            <div className="container">
                <div className="cta-bg bg-theme">
                    <div className="cta-number">
                        <div className="cta-number-icon mr-30">
                            <i className="flaticon-reaction"></i>
                        </div>
                        <div className="cta-number-text fix">
                            <span className="uppercase">Have an Interest in AI Research?</span>
                            <h3><Link to="/admissions-office" className="text-white">Contact Us</Link></h3>
                        </div>
                    </div>
                    <div className="cta-description">
                        <p>We are specialized in AI research focusing on AI development across a broad spectrum of life and scientific branches.</p>
                    </div>
                    <div className="cta-btn text-lg-end text-start">
                        <Link to="/journal-of-aii" className="theme-btn white-btn">MAI Publications</Link>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Cta;