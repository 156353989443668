import React, { useState } from 'react';
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap'; 
import { Link } from 'react-router-dom';


const WhyWeTab = () => {

    const [open, setOpen] = useState('');
    const toggle = (id) => {
      if (open === id) {
        setOpen();
      } else {
        setOpen(id);
      }
    };

    return (
        
        <div className="why-we-tab mb-40" data-aos="fade-up-left" data-aos-duration="1000">
            <Tabs>
                <div className='why-tabs'>
                    <TabList>
                        <Tab><button className="nav-link-about" type="button">Why study AI ?</button></Tab>
                        <Tab><button className="nav-link-about" type="button">Why MAI ?</button></Tab>
                        <Tab><button className="nav-link-about" type="button">Who we are ?</button></Tab>
                    </TabList>
                </div>

                <div className='why-tabs-content'>
                    <TabPanel>
                        <div className="tab-panes">
                            <Accordion flush open={open} toggle={toggle} className='accordion-flush about-accordion'>
                                <AccordionItem className="accordion  bg-transparent">
                                    <AccordionHeader targetId="1" className="">To Thrive Career and Business Opportunities</AccordionHeader>
                                    <AccordionBody accordionId="1" className="">
                                        <strong>Embrace the vast potential of AI and unlock a world of exciting and evolving career and business opportunities in every field.</strong>
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem className="accordion bg-transparent">
                                    <AccordionHeader targetId="2">To Survive Disruptive Job Market Changes</AccordionHeader>
                                    <AccordionBody accordionId="2" className="">
                                        <strong>Imagine a future where the rapid rise of artificial intelligence creates an astonishing 97 million new job opportunities by 2025, while also transforming other industries and leading to a loss of 85 million jobs. (The Future of Job Report 2020–2025. 2020. World Economic Forum).</strong>
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem className="accordion bg-transparent">
                                    <AccordionHeader targetId="3">To Unleash Boundless Creative Potential</AccordionHeader>
                                    <AccordionBody accordionId="3" className="">
                                        <strong>Immerse yourself in the realm of AI and unlock limitless opportunities for imaginative exploration, where you can develop intelligent systems, pioneer innovative applications, and shape transformative solutions.</strong>
                                    </AccordionBody>
                                </AccordionItem>
                            </Accordion>
                            {/* <p className="tab-text mb-30">AI continues to shape the future, how about you? Do you plan to stay adaptable to this trend?</p> */}
                            
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="tab-panes">
                            <Accordion flush open={open} toggle={toggle} className='accordion-flush about-accordion'>
                                <AccordionItem className="accordion  bg-transparent">
                                    <AccordionHeader targetId="1" className="text-dark">We empower you with the latest AI skills through advanced teaching techniques</AccordionHeader>
                                    <AccordionBody accordionId="1" className="">
                                        <ul className="about-whytablist fix">     
                                            <li>We are more adaptive to latest technologies than universities</li>
                                            <li>We follow latest trends of education and use latest education tools</li>
                                            <li>The practical skills attained with us in your chosen AI field will be equivalent to that achieved through a bachelor's degree</li>
                                        </ul>
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem className="accordion bg-transparent">
                                    <AccordionHeader targetId="2">Students from around 170 countries choose us</AccordionHeader>
                                    <AccordionBody accordionId="2" className="">
                                        <ul className="about-whytablist fix">
                                            <li>We stand out as a trusted AI course provider, offering personalized support, flexible learning options, and a diverse community that appeals to aspiring AI professionals worldwide</li>
                                            <li>We collaborate with and earning the trust of governments in numerous countries. Our mission is to support these nations in embracing the transformative AI landscape, ensuring they remain at the forefront of this evolving trend"</li>
                                            <li>As a pioneer in AI education, our commitment to staying at the forefront of industry trends attracts students from around 170 countries, eager to explore the limitless possibilities of AI</li>
                                        </ul>
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem className="accordion bg-transparent">
                                    <AccordionHeader targetId="3">You become eligible to our Career Suite and AiStart-Up™ programs</AccordionHeader>
                                    <AccordionBody accordionId="3" className="">
                                        <ul className="home-whytablist fix">
                                            <li>You become a part of our Alumni Family, being eligible to our Career Suite and Start-Up programs.</li>
                                            {/* <li>We collaborate with and earning the trust of governments in numerous countries. Our mission is to support these nations in embracing the transformative AI landscape, ensuring they remain at the forefront of this evolving trend"</li>
                                            <li>As a pioneer in AI education, our commitment to staying at the forefront of industry trends attracts students from around 170 countries, eager to explore the limitless possibilities of AI</li> */}
                                        </ul>
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem className="accordion bg-transparent">
                                    <AccordionHeader targetId="4">You can be eligible for&nbsp;<strong>Scholarship</strong>, apply now!</AccordionHeader>
                                    <AccordionBody accordionId="4" className="">
                                        <div className="accordion-body ">
                                            Our course fees consists of two parts; course programs and Career Suite program. 
                                            Many of our courses are funded by industry through our Career Suite program, this makes many available places with scholarships
                                            <div className="mslider-btn pt-3 pr-0" data-animation="fadeInUp" data-delay="0.7s">
                                                <Link to="/schools"><a className="slider-btn">Select a Program</a></Link>
                                            </div>
                                        </div>
                                    </AccordionBody>
                                </AccordionItem>
                            </Accordion>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="tab-panes">
                            <p className="mb-30 ">We are leading global AI education provider, AI research 
                                                    and development ogranization.</p>
                            <div className="why-tab-wrapper">
                                {/* <div className="why-tab-img">
                                    <img src="assets/img/about/about-img-8.jpg" className="img-fluid" alt=""/>
                                </div> */}
                                <div className="about-whytablist fix">
                                    <ul>
                                        <li><i className="fal fa-check"></i>Delivering Cutting-Edge AI Education for the Next Generation</li>
                                        <li><i className="fal fa-check"></i>Building an Elite Community of AI Professionals</li>
                                        <li><i className="fal fa-check"></i>Empowering Industry Leaders with Advanced AI Expertise</li>
                                        <li><i className="fal fa-check"></i>Crafting Curricula Aligned with the Latest AI Research</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                </div>
            </Tabs>
        </div>
    );
};

export default WhyWeTab
