import React from 'react';
import ArticleLayout from './ArticleLayout';
import postsData from './blog-posts/posts.json';
import JournalBanner from './JournalBanner';
import SidebarPost from './SidebarPost';
import PaginationSection from '../../Components/Common/Pagination';

// Helper function to sort posts by date in descending order
function sortPostsByDateDescending(posts) {
    return posts.sort((a, b) => new Date(b.date) - new Date(a.date));
}

const ArticleMain = () => {
    // Sort posts data before rendering
    const sortedPosts = sortPostsByDateDescending(postsData);

    return (
        <main>
            {/* JournalBanner-start */}
            <JournalBanner pageTitle="Blog" />
            {/* JournalBanner-end */}

            <section className="blog-2 pt-120 pb-115">
                <div className="container">
                    <div className='row'>
                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12">
                            {sortedPosts && sortedPosts.map((post, i) => (
                                <div key={i} className="mb-30">
                                    <ArticleLayout post={post} />
                                </div>
                            ))}
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 mt-md-40 mt-xs-40">
                            <div className="sidebar-wrap">
                                <SidebarPost />
                            </div>
                        </div>
                    </div>
                    <PaginationSection />
                </div>
            </section>
        </main>
    );
}

export default ArticleMain;