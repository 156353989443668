import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Form, Row, Col, Label, Input, Button, Alert } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { functions } from '../../firebase';
import { httpsCallable } from 'firebase/functions';

const initialValues = {
  name: '',
  email: '',
  company: '',
  subject: '',
  message: '',
};

const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email address').required('Please Enter Your Email'),
  name: Yup.string().required('Please Enter Your Name'),
  company: Yup.string(),
  subject: Yup.string().required('Please Enter Your Subject'),
  message: Yup.string().required('Please Enter Your Message'),
});

const ContactForm = () => {
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const sendEmail = httpsCallable(functions, 'sendEmail');
        await sendEmail(values);
        console.log('Email sent successfully!');
        resetForm();
        toast.success('Message Sent Successfully', {
          position: 'top-right',
          hideProgressBar: false,
          className: 'bg-success text-white',
          toastId: '',
        });
      } catch (error) {
        console.error('Error sending email:', error);
        toast.error('Oops! Something went wrong, please try again later.', {
          position: 'top-right',
          hideProgressBar: false,
        });
      }
    },
  });

  return (
    <div className="contact-form">
      <Form onSubmit={formik.handleSubmit} className='contact-widget'>
        <Row className='row-control'>
          <Col md={6} lg={6} xl={6} xxl={6} className="mb-20">
            <Input
              type="text"
              id="name"
              name="name"
              className="form-control custom-input"
              placeholder="Your Name *"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="text-danger">{formik.errors.name}</div>
            ) : null}
          </Col> 

          <Col md={6} lg={6} xl={6} xxl={6} className="mb-20">
            <Input
              type="email"
              id="email"
              name="email"
              className="form-control custom-input"
              placeholder="Email Adress *"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="text-danger">{formik.errors.email}</div>
            ) : null}
          </Col>
        </Row>

        <Row className='row-control'>
          <Col md={6} lg={6} xl={6} xxl={6} className="mb-20">
            <Input
              type="text"
              id="company"
              name="company"
              className="form-control custom-input"
              placeholder="Organization"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.company}
            />
            {formik.touched.company && formik.errors.company ? (
              <div className="text-danger">{formik.errors.company}</div>
            ) : null}
          </Col>

          <Col md={6} lg={6} xl={6} xxl={6} className="mb-20">
            <Input
              type="text"
              id="subject"
              name="subject"
              className="form-control custom-input"
              placeholder="Subject *"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.subject}
            />
            {formik.touched.subject && formik.errors.subject ? (
              <div className="text-danger">{formik.errors.subject}</div>
            ) : null}
          </Col>
        </Row>
        <Row className='row-control'>
          <Col md={12} lg={12} xl={12} xxl={12} className="mb-20">
            <Input
              type="textarea"
              id="message"
              name="message"
              className="custom-input textarea"
              placeholder="Your Message *"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.message}
            />
            {formik.touched.message && formik.errors.message ? (
              <div className="text-danger">{formik.errors.message}</div>
            ) : null}
          </Col>
        </Row>
        <Row className='mt-5'>
          <div className="col-xxl-12 col-xl-12 mb-20 d-flex justify-content-between">
            <button type="submit" className="theme-btn border-btn">Send the message</button>
            <div className="d-flex align-items-center">
              <div className="admin-office-icon">
                <a href="#"><i className="fal fa-comments"></i></a>
              </div>
              <div className="contact-info-text mt-10">
                <span>Message Anytime</span>
                <h5><a href="#">Use the Chat Box</a></h5>
              </div>
            </div>
          </div>
        </Row>
        <ToastContainer autoClose={2000} limit={1} />
      </Form>
    </div>
  );
};

export default ContactForm;