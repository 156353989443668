import React, { useEffect, useState } from 'react';
import GetNextDayOfWeek from "../../Components/Common/GetNextDayOfWeek";

import factIcon1 from '../../assets/img/icon/fact-icon-1.png';
import factIcon2 from '../../assets/img/icon/fact-icon-2.png';
import factIcon3 from '../../assets/img/icon/service-icon-9.png';
import factIcon4 from '../../assets/img/icon/fact-icon-4.png';



const CourseFacts = ({department, programLink}) => {

    const [programDetails, setProgramDetails] = useState(null);

    useEffect(() => {
        const programs = department?.allprograms
        const program = programs?.find(p => p.titleString ===  `/${programLink}`);
        setProgramDetails(program);
    }, [department, programLink]);

    if (!programDetails) {
        return <div>Loading...</div>;
    }

    return (
    <section className="fact-area pb-25">
        <div className="container">
            <div className="fun-fact text-center pb-40">
                <span>Program Details</span>
            </div>
        </div>
        <div className="container">
            <div className="row gx-0">
                <div className="col-lg-3 col-sm-6">
                    <div className="kfact text-center mb-30 wow fadeInUp" data-wow-duration=".6s">
                        <div className="kfact-icon">
                            <i><img src={factIcon2} className="img-fluid" alt="fact-icon-img"/></i>
                        </div>
                        <span>Program Start Date</span>
                        <h2 className="fact-title text-dark counter">
                           <GetNextDayOfWeek startDayofWeek={department.startDayofWeek} />
                        </h2>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                    <div className="kfact text-center mb-30 wow fadeInUp" data-wow-duration=".3s">
                        <div className="kfact-icon">
                            <i><img src={factIcon1} className="img-fluid" alt="fact-icon-img"/></i>
                        </div>
                        <span>Program Duration</span>
                        <h2 className="fact-title counter">{programDetails.programDuration}</h2>
                        <p className="">12 hours/week </p>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                    <div className="kfact text-center mb-30 wow fadeInUp" data-wow-duration=".9s">
                        <div className="kfact-icon">
                            <i><img src={factIcon3} className="img-fluid" alt="fact-icon-img"/></i>
                        </div>
                        <span>Total Projects</span>
                        <h2 className="fact-title counter">5</h2>   
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                    <div className="kfact kfact-last-child text-center mb-30 wow fadeInUp" data-wow-duration="1s">
                        <div className="kfact-icon">
                            <i><img src={factIcon4} className="img-fluid" alt="fact-icon-img"/></i>
                        </div>
                        <span>Instructors</span>
                        <h2 className="fact-title counter">A Team of Experts</h2>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
}

export default CourseFacts;