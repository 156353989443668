import React from 'react';
import Header from '../../Components/Layout/Header/Header';
import Footer from '../../Components/Layout/Footer/Footer';
import ServiceDetailsMain from './ServiceDetailsMain';

class ServiceDetailsPage extends React.Component {

    static getInitialProps({store}) {}

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
            <Header />
            <ServiceDetailsMain />
            <Footer />
            </React.Fragment>
        );
    }
}

export default ServiceDetailsPage;

