import React, { useState } from 'react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, } from 'swiper';
import { EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/bundle';
import { Link } from 'react-router-dom';
import QuillTextRenderer from "../../Components/Common/QuillTextRenderer";
import genaidepBg from '../../assets/img/bg/course1-bg.png';

const smoothScrollToElement = (elementId, headerHeight) => {
    const element = document.getElementById(elementId);
    if (element) {
        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset - headerHeight;
        window.scrollTo({
            top: elementPosition,
            behavior: 'smooth'
        });
    }
};


const ProgramBanner = ({ program }) => {
    const syllabusClick = () => smoothScrollToElement('syllabus', 100);
    const enrollNowClick = () => smoothScrollToElement('pricing-section', 100);

    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    return (
        <section className="slider-area fix position-relative">
            <div className="slider-active swiper-container">
                <div className="swiper-wrapper">
                    <Swiper
                        modules={[Navigation, Pagination, Scrollbar, A11y, EffectFade, Autoplay]}
                        effect="fade"
                        spaceBetween={30}
                        slidesPerView={1}
                        autoplaydisableoninteraction={"false"}
                        loop={true}
                        breakpoints={{
                            768: {
                                slidesPerView: 1
                            },
                            1200: {
                                slidesPerView: 1,
                            }
                        }}
                        // autoplay= {{
                        //     delay: 3000,
                        //     disableOnInteraction: true
                        // }}
                        pagination={{ clickable: true }}
                    >
                        <SwiperSlide>
                            <div className="single-slider" data-swiper-autoplay="10000" >
                                <div className="slide-bg" style={{ backgroundImage:`url(${genaidepBg})`}}></div>
                                <div className="container">
                                    <div className="row d-flex justify-content-between">
                                        <div className="col-lg-6">
                                            <div className="mslider pb-80 pt-60 z-index">
                                                <h5 className="banner--subtitle mb-25" data-animation="fadeInUp" data-delay="0.3s">Professional Certificate in </h5>
                                                <h2 className="mslider--title mb-40" data-animation="fadeInUp" data-delay="0.5s">{program.programTitle}</h2>
                                                <p className="description text-light">
                                                    <QuillTextRenderer
                                                        textToRender={program.introduction}
                                                    />
                                                </p>
                                                <div className="d-flex items-center justify-content-left mt-5 md-mt-10 mb-140">
                                                    <i className="dep-btn align-self-center p-2"></i>
                                                    <div  className="mslider-btn coursebanner" data-animation="fadeInUp" data-delay="0.7s">  
                                                        {/* <Link onClick={syllabusClick}><a className="slider-btn">Syllabus</a></Link> */}
                                                    </div>
                                                    <div className="mslider-btn" data-animation="fadeInUp" data-delay="0.7s">
                                                        <Link onClick={enrollNowClick}><a className="slider-btn">Enroll Now</a></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
            <span className="bg-accent position-absolute bottom-75 h-6 w-50"></span>
        </section>
    );
}

export default ProgramBanner;