import React from 'react';
import "./styles.css";

const Preloader = () => {
  return (
    <div className="preloader js-preloader">
      <span className="preloader__circle preloader__circle--primary"></span>
      <span className="preloader__circle preloader__circle--secondary"></span>
      <span className="preloader__circle preloader__circle--tertiary"></span>
    </div>
  );
};

export default Preloader;

