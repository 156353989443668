import React, { Component } from 'react';
import serviceIcon from "../../assets/img/icon/service-icon-1a.png";
import ctaImg from "../../assets/img/trust/cta-img.jpg";

class Quality extends Component {

    render() {

        return (
            <section className="quality-area pt-120 pb-90 fix">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-8">
                        <div className="kquality mb-30" data-aos="zoom-in-right">
                            <div className="kquality-icon">
                                <img src={serviceIcon} className="img-fluid" alt="about-icon"/>
                            </div>
                            <div className="kquality-text fix sides-padding">
                                <h3 className="mb-20 kquality-text-title">We Deliver the Best Quality</h3>
                                <p> <span className="text-primary fw-bold">Experience Unmatched Quality: </span>Our mission drives us to deliver the utmost quality. Through our cutting-edge approach, we go beyond routine lectures and passive learning. With active learning at the core of our methodology, you'll engage in immersive and interactive experiences that spark your curiosity and foster deeper understanding. Join us and embark on a transformative learning journey where excellence meets the future of education.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="text-lg-end mb-30" data-aos="zoom-in-left">
                            <div className="kquality-img">
                                <img src={ctaImg} className="img-fluid" alt="cta-img"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        );
    }
}

export default Quality;