import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFunctions } from 'firebase/functions';


const firebaseConfig = {
    apiKey: "AIzaSyBV8CV_SA3o4-EXdgBWR8oXM5hDvBAeFdU",
    authDomain: "metty-6c211.firebaseapp.com",
    projectId: "metty-6c211",
    storageBucket: "metty-6c211.appspot.com",
    messagingSenderId: "1008367982301",
    appId: "1:1008367982301:web:a51ed4d6c95fa2edf0ad34",
    measurementId: "G-K7JQ4D8QZE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const functions = getFunctions(app);

export { app, functions };