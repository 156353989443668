function formatNumber(number) {
    return new Intl.NumberFormat('en-US').format(number);
}

const price = [
    {
        id: 1,
        type: "Pay as-you-go",
        rate: 330,
        description: "Ideal for self-paced learners seeking a flexible AI learning opportunity.",
        supportClass: "danger",
        supportSymbol: "close",
        ribbon: false,
        planButtonClassname: "primary",
        btntxt: "Choose Plan",
        delrateYear: "$450"
    },
    {
        id: 2,
        type: "Individual Program",
        rate: 600,
        description: "This plan includes only the program. It is an excellent choice for advancing skills in this specialized AI domain.",
        supportClass: "danger",
        supportSymbol: "close",
        ribbon: "cheap",
        planButtonClassname: "primary",
        btntxt: "Choose Plan",
        delrateYear: "600"
    }
];

// Format the 'rate' field in the pricing data
const pricing = price.map(item => ({
    ...item,
    rate: formatNumber(item.rate)
}));

export default pricing;