import React, { useState, useEffect } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import BurgerMenus from './BurgerMenus';
import HeaderSearch from './HeaderSearch';
import DynamicNavDropdown from './DynamicNavDropdown';
import Logo from '../../../assets/img/logo/logo-white.png';

const Header = () => {
   const [menuOpen, setMenuOpen] = useState(false);
   const [searchOpen, setSearchOpen] = useState(false);

   const location = useLocation();
   const [path, setPath] = useState("");
   useEffect(() => {
      setPath(location.pathname);
   }, [location]);

   // Sticky Menu Area start
   useEffect(() => {
      window.addEventListener('scroll', sticky);
      return () => {
         window.removeEventListener('scroll', sticky);
      };
   });

   const sticky = (e) => {
      const header = document.querySelector('.transparent-header');
      const scrollTop = window.scrollY;
      scrollTop >= 1 ? header.classList.add('sticky-header') : header.classList.remove('sticky-header');
   };
   // Sticky Menu Area End

   return (
      <React.Fragment>
         <header>
            <div className="transparent-header">
               <div className="container-fluid">
                  <div className="header-space">
                     <div className="row align-items-center">
                        <div className="col-xl-2 col-8">
                           <div className="header-logo">
                              <Link to="/"><span><img src={Logo} className="img-fluid" alt="logo-img" /></span></Link>
                           </div>
                        </div>
                        <div className="col-xl-7 col-4">
                           <div className="d-xl-block d-none">
                              <Navbar id="mobile-menu" bg="transparent" expand="lg">
                                 <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                 <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav className="ms-3 navbar-nav">
                                       <Nav.Link href="/">Home</Nav.Link>
                                       <Nav.Link href="/about">About</Nav.Link>
                                       <DynamicNavDropdown />
                                       <Nav.Link href="/enterprises">For Enterprises</Nav.Link>
                                       <Nav.Link href="/governments">For Governments</Nav.Link>
                                       <Nav.Link href="/research">Research</Nav.Link>
                                    </Nav>
                                 </Navbar.Collapse>
                              </Navbar>
                           </div>
                           {/* <div className="side-menu-icon d-xl-none text-end">
                              <span className="search-btn nav-search search-trigger" onClick={() => { setSearchOpen(!searchOpen) }}><i className="fas fa-search"></i></span>
                           </div> */}
                           <div className="d-xl-none d-flex align-items-center justify-content-end">
                              <div className="d-none d-xl-none d-lg-flex d-md-flex d-sm-flex align-items-center justify-content-end">
                                 <div className="header-btn ms-2">
                                    <Link to="/admissions-office" rel="noopener noreferrer">
                                       <span className="theme-btn theme-btn-small">Admissions</span>
                                    </Link>
                                 </div>
                                 <div className="header-btn ms-2">
                                    <Link to={"https://www.metacenter.metaversityai.ai/login"} rel="noopener noreferrer">
                                       <span className="theme-btn theme-btn-small">Login</span>
                                    </Link>
                                 </div>
                              </div>
                              <span className="search-btn nav-search search-trigger" onClick={() => { setSearchOpen(!searchOpen) }}><i className="fas fa-search"></i></span>
                              <button className="side-toggle ms-2" onClick={() => { setMenuOpen(!menuOpen) }}><i className="far fa-bars"></i></button>
                           </div>
                        </div>
                        <div className="col-xl-3 d-none d-xl-flex justify-content-end">
                           <div className="header-right text-end">
                              <div className="header-btn">
                                 <Link to="/admissions-office" rel="noopener noreferrer">
                                    <span className="theme-btn theme-btn-small">Admissions</span>
                                 </Link>
                              </div>
                              <div className="header-btn ps-3">
                                 <Link to={"https://www.metacenter.metaversityai.ai/login"} rel="noopener noreferrer">
                                    <span className="theme-btn theme-btn-small">Login</span>
                                 </Link>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <BurgerMenus menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
            <div onClick={() => setMenuOpen(false)} className={menuOpen ? "body-overlay show" : "body-overlay"}></div>

            <HeaderSearch searchOpen={searchOpen} setSearchOpen={setSearchOpen} />
            <div onClick={() => setSearchOpen(false)} className={searchOpen ? "body-overlay show" : "body-overlay"}></div>

         </header>
      </React.Fragment>
   );
};

export default Header;
