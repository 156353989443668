import React from 'react';
import QuillTextRenderer from "../../Components/Common/QuillTextRenderer";

import factBg from '../../assets/img/bg/fact-bg.jpg';
import factIcon1 from '../../assets/img/icon/service-icon-10.png';
import factIcon2 from '../../assets/img/icon/about-icon.png';


const Prerequisite = ({program}) => {
  return <section className="fact-area pb-45" style={{ backgroundImage: `url(${factBg})`}}>
            <div className="container">
                <div className="fun-fact text-center pb-40">
                    <span>Prerequisites - | - Skills Achieved</span>
                </div>
            </div>
            <div className="container">
                <div className="row gx-0">
                    <div className="col-lg-6 col-sm-6">
                        <div className="prerequisite mb-10 wow fadeInUp" data-wow-duration=".3s">
                            <div className="kfact-icon">
                                <i><img src={factIcon1} className="img-fluid" alt="fact-icon-img" /></i>
                            </div>
                            <h2 className="kfact-title">Prerequisites</h2>
                            <div className="why-tab-list mb-40">
                                <ul>
                                    <li><i className="far fa-check"></i><span>{program.prerequisites}</span></li>
                                </ul>
                            </div>
                            <span className="pe-4">
                                <QuillTextRenderer textToRender={program.whoIsItFor}/>
                            </span>
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-6">
                        <div className="prerequisite mb-10 wow fadeInUp" data-wow-duration=".3s">
                            <div className="kfact-icon">
                                <i><img src={factIcon2} className="img-fluid" alt="fact-icon-img" /></i>
                            </div>
                            <h2 className="kfact-title pb-3">Skills You'll Achieve</h2>
                            <span className="pt-5">
                                <QuillTextRenderer
                                    textToRender={program.whatYouLearn}
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>;
};

export default Prerequisite;