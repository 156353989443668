import React, { useEffect, useState } from 'react';
import QuillTextRenderer from "../../Components/Common/QuillTextRenderer";
import sideImg from '../../assets/img/bg/Artboard 1.png';

const WhyWeThree = ({department, programLink}) => {
    const [programDetails, setProgramDetails] = useState(null);

    useEffect(() => {
        const programs = department?.allprograms
        const program = programs?.find(p => p.titleString ===  `/${programLink}`);
        setProgramDetails(program);
    }, [department, programLink]);

    if (!programDetails) {
        return <div>Loading...</div>;
    }


    return <section className="why-we pt-120 pb-125 pb-md-110 position-relative">
        <div className="common-shape-wrapper wow slideInLeft animated" data-wow-delay="3ms" data-wow-duration="1500ms">
            <div className="common-shape-inner wow slideInleft animated" data-wow-delay="3ms" data-wow-duration="1500ms"></div>
        </div>
        <div className="container z-index">
            <div className="row justify-content-between">
                <div className="d-flex justify-content-center mb-50">
                    <h2 className="youlearn mb-35">Learning Outcomes</h2>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 mt-3">
                    <div className="provide-thumb mt-5">
                        <img src={`${department?.depImage}`} alt=""/>
                    </div>
                    <div>
                        <img src={sideImg} alt=""/>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                    <div className="whatlearn-content sides-padding">
                        <div className="section-title-wrapper mb-25">
                            <h5 className="section-subtitle mb-20">Get Certified Within {programDetails.programDuration}!</h5>
                            <h2 className="youlearn mb-35">{programDetails.programTitle}<br />
                            </h2>
                            {/* <p>WE ARE TRUSTED BY THE GLOBAL BLOCKCHAIN ECOSYSTEM.</p> */}
                        </div>
                        <p>
                            <QuillTextRenderer
                                textToRender={programDetails.description}
                            />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>;
};

export default WhyWeThree;