import React from 'react';
import { Link } from 'react-router-dom';
import whyweImg from '../../assets/img/about/enterprise.jpg';

const WhyWeThree = () => {
    return <section className="why-we bg-grey pt-120 pb-125 pb-md-110 position-relative">
        <div className="common-shape-wrapper wow slideInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms">
            <div className="common-shape-inner wow slideInleft animated" data-wow-delay="0ms" data-wow-duration="1500ms"></div>
        </div>
        <div className="container z-index">
            <div className="row justify-content-between">
                <div className="col-xl-7 col-lg-7 col-md-12">
                    <div className="provide-content sides-padding">
                        <div className="section-title-wrapper mb-25">
                            <h5 className="section-subtitle mb-20">Ensuring Being Beyond University - a Metaversity</h5>
                            <h2 className="section-title mb-35">MAI Education Quality Assurance Standards (EQAS)</h2>
                        </div>

                        <h2>Industry-Aligned Curriculum Development</h2>
                        <p>
                            Our curriculum is designed in accordance with accreditation standards established by
                            recognized accrediting bodies, augmented by the latest advancements in AI education
                            research. It is developed collaboratively with over 210 AI organizations/companies within
                            our network to ensure alignment with current industry demands.
                        </p>

                        <h2>Rigorous Academic Standards</h2>
                        <p>
                            Courses are delivered through an interactive, self-paced learning platform with live
                            instructor support, as well as through live sessions led by seasoned AI professionals and
                            researchers to ensure high-quality instruction.
                        </p>
                        <p>
                            Our programs incorporate continuous learning tracking and comprehensive assessments,
                            including practical projects, research projects, examinations, and peer reviews, to ensure
                            deep understanding and skill development.
                        </p>

                        <h2>Flexibility and Adaptability</h2>
                        <p>
                            We rapidly adapt our courses to reflect emerging trends and technologies, maintaining the
                            relevance and applicability of our education.
                        </p>
                        <p>
                            Our teaching platform and methods are continually refined to incorporate innovative
                            approaches and feedback from students and industry partners.
                        </p>

                        <h2>Practical Application and Hands-On Learning</h2>
                        <p>
                            Students engage in hands-on projects that address real-world challenges, providing
                            practical experience.
                        </p>
                        <p>
                            The hands-on projects are designed primarily for educational purposes and may not result
                            in business-ready projects. However, our career package plan supports hands-on projects to
                            be market-ready, ensuring students gain valuable experience and skills that are directly
                            applicable in the industry.
                        </p>
                        <p>
                            For career package plans, we offer internship opportunities with leading AI companies to
                            bridge the gap between theoretical knowledge and practical application.
                        </p>

                        <h2>Specialized and Niche Programs</h2>
                        <p>
                            We design specialized programs to address unique challenges and opportunities in the AI
                            field, catering to the specific needs of our students and the industry.
                        </p>
                        <p>
                            Our programs encourage research, innovation, and creativity, preparing students to lead in
                            the development of new AI solutions.
                        </p>

                        <h2>Continuous Improvement and Feedback</h2>
                        <p>
                            We actively seek and incorporate feedback from students to continuously improve our
                            courses and teaching methods.
                        </p>
                        <p>
                            Regular consultations with our network of AI companies ensure our programs remain
                            aligned with industry standards and expectations.
                        </p>

                        <h2>Comprehensive Support Services</h2>
                        <p>
                            We provide robust career support, including job placement assistance, profile building, and
                            interview preparation.
                        </p>
                        <p>
                            Access to a wide range of learning resources, including online libraries, AI tools, and
                            mentorship from industry experts.
                        </p>

                        <h2>Ethical Standards and Inclusivity</h2>
                        <p>
                            Our courses emphasize the importance of ethical considerations in AI development and
                            application.
                        </p>
                        <p>
                            We foster an inclusive learning environment that values diversity and promotes equal
                            opportunities for all students.
                        </p>
                    </div>
                </div>
                <div className="col-xl-5 col-lg-5 col-md-12">
                    <div className="position-relative mt-5 mb-20">
                        <img src={whyweImg} alt="" />
                        <div className="mt-160">
                            <div className="mission-img-text">
                                <h4>We’re Committed to
                                    Being a Trusted Partner
                                </h4>
                                <Link to="/admissions-office"><a>Contact Us</a></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>;
};

export default WhyWeThree;