import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import withRouter from '../Common/withRouter';

//import Components
import Header from './Header/Header';
import Footer from './Footer/Footer';
import SupportChat from './SupportChat/SupportChat';



const Layout = (props) => {

    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Header/>
                <div className="main-content">{props.children}
                    <Footer />
                </div>
            </div>
            <SupportChat />
        </React.Fragment>

    );
};

Layout.propTypes = {
    children: PropTypes.object,
};

export default withRouter(Layout);