/* eslint-disable no-undef */
import React, { useState } from "react";
import { Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row } from "reactstrap";
import classnames from "classnames";
import { Link } from "react-router-dom";
import singlePrice  from '../../data/singlePricing';


const ProgramCart = ({ program }) => {
    const [activeTab, setActiveTab] = useState('1');
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    if (!program) {
        return <div>Loading ...</div>;
    }

    return (
        <React.Fragment>
            <div id="pricing-section" className="page-content">
                <Container fluid className="pricing-spacing">
                    <Row>
                        <Col xl={12}>
                            <Row className="justify-content-center row-spacing">
                                <Col lg={5}>
                                    <div className="text-center mb-4">
                                        <h4 className="fees-title">Choose a Plan</h4>
                                        <p className="text-muted mb-4 fs-15">Advanced options for your success.</p>

                                        <div className="d-inline-flex">
                                            <Nav className="nav-pills arrow-navtabs plan-nav rounded mb-3 p-1" id="pills-tab" role="tablist">
                                                <NavItem>
                                                    <NavLink
                                                        href="#"
                                                        className={classnames({ active: activeTab === '1' }, 'fw-semibold')}
                                                        onClick={() => { toggleTab('1'); }}
                                                    >
                                                        {program?.programTitle} {" "} / {" "} {program?.department}
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="row-spacing d-flex justify-content-center">
                                {(singlePrice || []).map((price, key) => (
                                    <Col xxl={3} lg={6} key={key}>
                                        <Card className="mb-3 pricing-box">
                                            <CardBody className="bg-light m-2 p-4">
                                                <div className="flex-grow-1 text-center mb-3">
                                                    <h3 className="mb-0 fw-semibold text-dark">{price.type}</h3>
                                                </div>
                                                <div className="d-flex d-block align-items-center mb-5">
                                                    <div className="ms-auto">
                                                        <h2 className="month mb-0 ms-3 me-5 text-primary">
                                                            ${price.rate}
                                                            {price.type === "Pay as-you-go" ? <small className="fs-12">/month</small> : ""}
                                                        </h2>
                                                    </div>
                                                </div>

                                                <p className="text-body mb-5">{price.description}</p>
                                                <div className="text-divider mb-5">Selected Program</div>
                                                <ul className="mt-3 mb-5 list-unstyled vstack gap-3">
                                                    <li>
                                                        <div className="d-flex">
                                                            <div className="flex-grow-1 text-break programTitle-text">
                                                                {program?.programTitle}
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <div className="mt-5 pt-2">
                                                    <Link to={`https://www.metacenter.metaversityai.ai/enrollment-page/${program?.id}?priceId=${price.id}`}
                                                        className={`btn btn-${price.planButtonClassname} ${price.btnstatus} w-100 btntxt-setting`}
                                                        rel="noopener noreferrer" 
                                                    >{price.btntxt}</Link>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                            <Row className="row-spacing mt-4">
                                <ul className="list-unstyled vstack text-center">
                                    <li className="ms-4 text-muted d-flex display-block justify-content-center flex-grow-1">
                                        <p><b className="text-dark">*</b> Upon completing this program, you will receive a certificate recognized by numerous leading AI companies worldwide.</p>
                                    </li>
                                    {/* <li className="ms-4 text-muted d-flex display-block flex-grow-1">
                                        <p><b className="text-dark">**</b> We support your ...</p> 
                                    </li>
                                    
                                    <li className="ms-4 text-muted d-flex display-block flex-grow-1">
                                        <p><b className="text-dark">***</b> Our CareerSuite project ...</p> 
                                    </li> */}
                                </ul>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ProgramCart;
