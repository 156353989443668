import React from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import schoolsData from '../../data/schoolsData.json';

const SiteMap = () => {
    document.title="SiteMap | Metaversity of Artificial Intelligence";
    return (
        <div className="page-content">
            <Container fluid>
                <Row className="mt-2">
                    <Col lg={12} className="mt-5">
                        <Card className="mt-5">
                            <CardHeader>
                                <h4 className="card-title mb-0">Site Map - Metaversity of Artificial Intelligence</h4>
                            </CardHeader>
                            <CardBody className="mb-5">
                                <div className="hori-sitemap">
                                    <ul className="list-unstyled mb-0">
                                        <li className="p-0 parent-title"><Link to="/" className="fw-semibold fs-14">Home</Link></li>
                                        <ul className="list-unstyled row g-0">
                                            <li className="col-sm-1">
                                                <Link to="/" className="fw-semibold sub-title">Home</Link>
                                                {/* <ul className="list-unstyled row g-0 second-list">
                                                    <li className="col-sm-6">
                                                        <Link to="#">Overview</Link>
                                                    </li>
                                                    <li className="col-sm-6">
                                                        <Link to="#">History</Link>
                                                    </li>
                                                </ul> */}
                                            </li>
                                            <li className="col-sm-1">
                                                <Link to="/about" className="fw-semibold sub-title">About</Link>
                                            </li>
                                            <li className="col-sm-3">
                                                <Link to="/" className="fw-semibold">Schools</Link>
                                                <ul className="list-unstyled second-list pt-0">
                                                    <li >
                                                        {schoolsData.map(school => (
                                                            <div key={school.id}>
                                                                <Link to={`/schools${school.schoolLink}`}>{school.schoolName}</Link>
                                                            </div>
                                                        ))}
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="col-sm-2">
                                                <Link to="/enterprises" className="fw-semibold sub-title">For Enterprises</Link>
                                            </li>
                                            <li className="col-sm-2">
                                                <Link to="/governments" className="fw-semibold sub-title">For Governments</Link>
                                            </li>
                                            <li className="col-sm-2">
                                                <Link to="/research" className="fw-semibold sub-title">Research</Link>
                                            </li>
                                            <li className="col-sm-1">
                                                <Link to="/admissions-office" className="fw-semibold sub-title">Contact Us</Link>
                                                {/* <ul className="list-unstyled row g-0 sub-list">
                                                    <li className="col-sm-6">
                                                        <Link to="#">Contact Us</Link>
                                                    </li>
                                                    <li className="col-sm-6">
                                                        <Link to="#">Customer Services</Link>
                                                        <ul className="list-unstyled second-list">
                                                            <li>
                                                                <div>
                                                                    <Link to="#">Chat With Us</Link>
                                                                    <Link to="#">Connect Information</Link>
                                                                    <Link to="#">FAQ'S</Link>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul> */}
                                            </li>
                                            {/* <li className="col-sm-3">
                                                <Link to="#" className="fw-semibold">Terms of Use</Link>
                                            </li> */}
                                        </ul>
                                    </ul>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default SiteMap;

