import React, { useState } from 'react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, } from 'swiper';
import { EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css/bundle';
import { Link } from 'react-router-dom';


import departmentBg from '../../assets/img/bg/department-bg.png';


const HomeSlider = () => {


    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    return (
        <section className="slider-area fix position-relative">
            <div className="slider-active swiper-container">
                <div className="swiper-wrapper">
                    <Swiper
                        modules={[Navigation, Pagination, Scrollbar, A11y, EffectFade, Autoplay]}
                        effect="fade"
                        spaceBetween={30}
                        slidesPerView={1}
                        autoplaydisableoninteraction={"false"}
                        loop={true}
                        breakpoints={{
                            768: {
                                slidesPerView: 1
                            },
                            1200: {
                                slidesPerView: 1,
                            }
                        }}
                        // autoplay= {{
                        //     delay: 3000,
                        //     disableOnInteraction: true
                        // }}
                        pagination={{ clickable: true }}
                    >
                    <SwiperSlide>
                        <div className="single-slider about-banner-height swiper-slides pb-150" data-swiper-autoplay="10000" >
                            <div className="slide-bg" style={{ backgroundImage:`url(${departmentBg})`}}></div>
                            <div className="container">
                                <div className="row d-flex justify-content-between">
                                    <div className="col-lg-6">
                                        <div className="mslider z-index">
                                            <h5 className="mslider--subtitle mb-25" data-animation="fadeInUp" data-delay="0.3s"></h5>
                                            <h2 className="mslider--title mb-40" data-animation="fadeInUp" data-delay="0.5s">Schools at <br/><span className="spanned-title">Metaversity of Artificial Intelligence</span></h2>
                                            <p className="description">
                                                We specialize in AI, we work with individuals, enterprises, and governments.
                                            </p>
                                            <p className="description">
                                                We provide updated AI Programs and apply latest education techniques.
                                                Choose programs at the schools that matches your career goal.
                                            </p>
                                        </div>
                                    </div>   
                                </div>
                            </div>
                            {/* <div className="slide-shape shape-1"><img src={slidershape1} className="img-fluid" alt="shape-img"/></div>
                            <div className="slide-shape shape-2"><img src={slidershape2} className="img-fluid" alt="shape-img"/></div> */}
                        </div>
                    </SwiperSlide>
                    </Swiper>
                </div>
                
                <div className="slider-paginations slide-dots"></div>
            </div>
            <span className="bg-accent position-absolute bottom-75 h-6 w-50"></span>
        </section>
    );
}

export default HomeSlider;