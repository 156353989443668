import React, { lazy } from 'react';
// import Mission from './MissionSection';
import FactTwo from './FactSectionTwo';
import AboutWhyWe from './AboutWhyWe';
import TeamTwo from './TeamSectionTwo';  
import AboutBanner from './AboutBanner';  
const BrandThree = lazy(() => import('../../Components/Elements/Brand/BrandSectionThree'))
// const Testimonial = lazy(() => import('./TestimonialsSection'))


const AboutMain = () => {

    return (
        <main>
            {/* banner-start */}
            <AboutBanner />
            {/* banner-end */}

            {/* why-we-start */}
            <AboutWhyWe />
            {/* why-we-end */}

            {/* testimonial-start */}
            {/* <div className='mt--30'>
                <Testimonial />
            </div> */}
            {/* testimonial-end */}

            {/* fact-start */}
            {/* <FactTwo /> */}
            {/* fact-end */}

            {/* mission-start */}
            {/* <Mission /> */}
            {/* mission-end */}

            {/* team-start */}
            {/* <TeamTwo /> */}
            {/* team-end */}

            {/* brand-start */}
            {/* <BrandThree /> */}
            {/* brand-end */}

        </main>
    );
}

export default AboutMain;