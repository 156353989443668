import { Link } from 'react-router-dom';
import Cat1 from '../../assets/img/catagory/intapps.png';
import Cat2 from '../../assets/img/catagory/cat-2.png';
import Cat3 from '../../assets/img/catagory/research.png';
//import Cat4 from '../../assets/img/catagory/cat-4.png';



const categoryData = [
   {
      id: 1,
      img: Cat1,
      title: 'IntellApps™ - Intelligent Applications',
      link: 'https://www.intellapps.io'
   },
   {
      id: 2,
      img: Cat2,
      title: 'IntellDocs™ - Science Management Tool',
      link: 'https://www.intelldocs.com'
   },
   {
      id: 3,
      img: Cat3,
      title: 'MAI Publications: Journal of AI Spectra',
      link: '/ai-spectra'
   },
   // {
   //    id: 4,
   //    img: Cat4,
   //    title: 'Digital Marketing'
   // },
]

const CategoryArea = () => {
   return (
      <>
         <section className="category__area pt-50 pb-135">
            <div className="container">
               <div className="row">
                  <div className="col-xxl-12">
                     <div className="section__title-wrapper text-center mb-60">
                        <h2 className="section-title"> MAI <br />Research & Innovation <br /> Platforms</h2>
                        <p>The Metaversity of Artificial Intelligence focuses on projects developing and integrating AI and AI-enhanced automation into various domains including science implementations within the following Research and Innovation Platforms.</p>
                     </div>
                  </div>
               </div>
               <div className="row">
                  {
                     categoryData.map((category, index) => {
                        return <div key={index} className="col-xxl-4 col-xl-4 col-md-6 col-sm-6">
                           <div className="category__item transition-3 text-center white-bg mb-30 wow fadeInUp" data-wow-delay=".3s" >
                              <div className="category__icon pt-40 mb-25">
                                 <a href={category.link} target="_blank" rel="noopener noreferrer"><img src={category.img} alt="" style={{height: 160}}/></a>
                              </div>
                              <div className="category__content">
                                 <h3 className="category__title">
                                    <a href={category.link} target="_blank" rel="noopener noreferrer">{category.title}</a>
                                 </h3>
                                 <Link to={category.link}>
                                    <a className="link-btn" href={category.link} target="_blank" rel="noopener noreferrer">
                                       <i className="far fa-long-arrow-right pe-2"></i>
                                       Learn More
                                    </a>
                                 </Link>
                              </div>
                           </div>
                        </div>
                     })
                  }
               </div>
            </div>
         </section>
      </>
   );
};

export default CategoryArea;