import React from 'react';
import { Link } from 'react-router-dom';
import whyweImg from '../../assets/img/about/enterprise.jpg';

const WhyWeThree = () => {
    return <section className="why-we bg-grey pt-120 pb-125 pb-md-110 position-relative">
        <div className="common-shape-wrapper wow slideInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms">
            <div className="common-shape-inner wow slideInleft animated" data-wow-delay="0ms" data-wow-duration="1500ms"></div>
        </div>
        <div className="container z-index">
            <div className="row justify-content-between">
                <div className="col-xl-6 col-lg-6 col-md-12">
                    <div className="position-relative mb-20">
                        <img src={whyweImg} alt="" />
                        <div className="mission-img-text mt-20">
                            <h4>We’re Committed to
                                Being a Trusted Partner
                            </h4>
                            <Link to="/admissions-office"><a>Contact Us</a></Link>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                    <div className="provide-content sides-padding">
                        <div className="section-title-wrapper mb-25">
                            <h5 className="section-subtitle mb-20">Empowering Your Workforce</h5>
                            <h2 className="section-title mb-35">Customized AI Training for Enterprises
                            </h2>
                            <p>Elevate your team’s capabilities with our tailored AI training programs.</p>
                        </div>
                        <p>At Metaversity AI, we excel in delivering customized AI education that aligns perfectly with your business objectives. Our courses are crafted to upskill your staff, enhancing their proficiency in specialized AI technologies and applications crucial to your industry.</p>
                        <p>With the option to tailor content and focus areas, you can ensure your team gains the specific expertise needed to drive innovation and secure a competitive advantage. Businesses partner with us to transform their workforce with cutting-edge AI knowledge.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>;
};

export default WhyWeThree;