import React from 'react';
import ContactForm from './ContactForm';

const Contact = () => {

    return (
            <section className="contact-area pt-120 pb-80 fix">
            <div className="container">
                <div className="row">
                    <div className="col-xxl-5 col-xl-6 col-lg-6">
                        <div className="section-title-wrapper mb-15">
                            <h5 className="section-subtitle mb-20">contact us</h5>
                            <h2 className="section-title">Speak with our
                                admissions office</h2>
                        </div>
                        <div className="contact-info mr-50 mr-xs-0  mr-md-0">
                            <div className="single-contact-info d-flex align-items-center">
                                <div className="contact-info-icon">
                                    <a href="#"><i className="ri-account-circle-line fs-4"></i></a>
                                </div>
                                <div className="contact-info-text mt-10">
                                    <span>Promotions Office</span>
                                    <h5 className="fw-bold"><a href="mailto:dr.chris@metaversityai.ai">Dr. Chris Galloway</a></h5>
                                    <h5><a href="mailto:dr.chris@metaversityai.ai">dr.chris@metaversityai.ai</a></h5>
                                </div>
                            </div>
                            <div className="single-contact-info d-flex align-items-center">
                                <div className="contact-info-icon">
                                    <a href="#"><i className="ri-team-line fs-4"></i></a>
                                </div>
                                <div className="contact-info-text mt-10">
                                    <span>Consultation Office</span>
                                    <h5 className="fw-bold"><a href="mailto:consult@metaversityai.ai">Consultants</a></h5>
                                    <h5><a href="mailto:consult@metaversityai.ai">consult@metaversityai.ai</a> </h5>
                                </div>
                            </div>
                            <div className="single-contact-info d-flex align-items-center">
                                <div className="contact-info-icon">
                                    <a href="#"><i className="fal fa-map-marker-alt"></i></a>
                                </div>
                                <div className="contact-info-text mt-10">
                                    <span>visit our office at</span>
                                    <h5><a href="#">1 Wagener Place, Auckland 1025, NZ</a></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-7 col-xl-6 col-lg-6">
                        <div className="contact-form mt-5">
                            
                                <div className="row mt-5">
                                    <ContactForm />
                                </div>
                                                      
                            <p className="ajax-response"></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contact;