import React, {useState} from 'react';
import { Button, Modal, ModalBody, ModalHeader} from 'reactstrap';
import { Link } from 'react-router-dom';
import QuillTextRenderer from "../../Components/Common/QuillTextRenderer";
import FeesSection from "../Courses/FeesSection";
import GetNextDayOfWeek from "../../Components/Common/GetNextDayOfWeek";


const SingleSchoolSection = ({school}) => {
    const [modal_fullscreen1, setmodal_fullscreen1] = useState(false);
    function tog_fullscreen1() {
        setmodal_fullscreen1(!modal_fullscreen1);
    }


    return (
        <section className="dep-details-area  pt-100 pb-100">
            <div className="container">
            {
                school.departments.map((department, index) => {
                return <div key={index}  className="row">
                    {department.allprograms.map((program, programIndex) => (
                    <div key={programIndex}>
                        <div  className="department-box">
                            <div className="dep-title-box">
                                <h3 className="department-title pb-30">
                                    <p>Department of {department.department}</p>
                                </h3>
                            </div>
                            
                                <div className="row align-items-center">
                                    <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-12">
                                        <div className="dep-single-comment department-item transition-3 white-bg mb-30 wow fadeInUp" data-wow-delay=".3s">
                                            <div className="row align-items-center">
                                                <div className="col-lg-3">
                                                    <div className="program-img pl-20">
                                                        <img src={`${department?.depImage}`} className="img-fluid" alt="prog-img"/>
                                                    </div>
                                                </div>
                                                <div className="col-lg-9">
                                                    <div className="coursename-text">
                                                        <Link to={program.programLink}><h4>{program.programTitle} </h4></Link>
                                                        <p> 
                                                            <QuillTextRenderer
                                                                textToRender={program.description}
                                                            />
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xxl-3 col-xl-3 col-lg-3">
                                        <div className="sidebar-wrap mb-30">
                                            <div className="course_tag_cloud inline-block">                                      
                                                <h4 key={index} className="course-info-title text-primary mb-15"> Starts on: </h4>
                                                <div className="bg-0 d-flex justify-content-center fw-bold">
                                                    <GetNextDayOfWeek startDayofWeek={department.startDayofWeek} />
                                                </div>
                                                
                                                    <div>
                                                        <div>
                                                            <a className="bg-0 fs-16 text-dark fw-bold">Level: </a>
                                                            <a className="bg-0 fs-16 text-primary fw-bold">{program.programLevel}</a>
                                                        </div>
                                                        <div className="bg-0 d-flex flex-column justify-content-center">
                                                            <Link onClick={() => tog_fullscreen1()} className="my-2 enrollBtn">
                                                                Enroll
                                                                <div className="enrollBtn2"></div>
                                                            </Link>
                                                            <Link to={program.programLink} className="my-2 enrollBtn">
                                                                More...
                                                                <div className="enrollBtn2"></div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            
                        </div>
                        <Modal
                            size="xl"
                            isOpen={modal_fullscreen1}
                            toggle={() => {
                                tog_fullscreen1();
                            }}
                            className="modal-fullscreen"
                            id="fullscreeexampleModal"
                        >
                            <ModalHeader className="modal-title"
                                id="fullscreeexampleModalLabel" toggle={() => {
                                    tog_fullscreen1();
                                }}>

                                {program.programTitle}

                            </ModalHeader>
                            <ModalBody>
                                <FeesSection program={program} />
                            </ModalBody>
                        
                        </Modal>
                    </div>
                    
                    ))}
                </div>
                })
            }
            </div>
        </section>
    );
}

export default SingleSchoolSection;