import React, { useEffect } from 'react';
//import Scss
import './assets/scss/main.scss';
import './assets/css/fontAwesome5Pro.css';
import './assets/css/flaticon.css';

import '../node_modules/bootstrap/scss/bootstrap.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/react-modal-video/scss/modal-video.scss';
import "../node_modules/react-accessible-accordion/dist/fancy-example.css";

//imoprt Route
import Routes from './Routes';

function App() {

  return (
    <React.Fragment>
      <Routes />
    </React.Fragment>
  );
}

export default App;
