import React from 'react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/bundle';
import { Link } from 'react-router-dom';
import GetNextDayOfWeek from "../../Components/Common/GetNextDayOfWeek";
import BgImage from '../../assets/img/course/course-bg-1.jpg';

const RelatedCourses = ({school, departmentDetails}) => {
    const Departments = school.departments;

    return (
        <section id="courseslider" className="blog-area pt-100 fix" style={{ backgroundImage:`url(${BgImage})`}}>
            <div className="container pb-120">
                <div className="blog-border">
                    <div className="blog-text blog-text-design" data-aos="zoom-in">
                        <div className="section-title-wrapper pr-25 mb-50">
                            <h5 className="section-subtitle mb-20">Further Your Learning in {departmentDetails.department}</h5>
                            <h2 className="section-title mb-35">Related Courses</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-xxl-8 col-xl-8 col-lg-8'>
                            <div className="blog-active swiper-container">
                                <div className="swiper-wrapper">
                                    <Swiper
                                        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                                        spaceBetween={30}
                                        slidesPerView={1}
                                        autoplaydisableoninteraction={"false"}
                                        loop={true}
                                        breakpoints={{
                                            480: {
                                                slidesPerView: 1,
                                            },
                                            576: {
                                                slidesPerView: 1,
                                            },
                                            768: {
                                                slidesPerView: 2,
                                            },
                                            1200: {
                                                slidesPerView: 2,
                                            },
                                            1400: {
                                                slidesPerView: 3
                                            }
                                        }}
                                        autoplay= {{
                                            delay: 3000,
                                            disableOnInteraction: true
                                        }}
                                        navigation={{
                                            clickable: true,
                                            nextEl: '.blog-button-next',
                                            prevEl: '.blog-button-prev',
                                        }}
                                    >
                                        {Departments.map((department, deptIndex) => (
                                            <React.Fragment key={deptIndex}>
                                                <div className="section-title-wrapper pr-25 mb-50">
                                                    <h5 className="section-subtitle mb-20">Department: {department.department}</h5>
                                                </div>
                                                {department.allprograms.map((course, courseIndex) => (
                                                    <SwiperSlide key={`${deptIndex}-${courseIndex}`}>
                                                        <div className="kblog swiper-slides">
                                                            <div className="kblog-img">
                                                                <Link to={course.programLink}><img src={department.depImage} className="img-fluid" alt="blog-img" /></Link>
                                                                <span className='pb-0 d-flex d-inline-flex'>Starts on:&nbsp;
                                                                    <GetNextDayOfWeek startDayofWeek={department.startDayofWeek || ''} />
                                                                </span>
                                                            </div>
                                                            <div className="kblog-text">
                                                                <div className="kblog-meta">
                                                                    <Link to={department.depLink}><i className="fal fa-user-circle"></i>Dep: {department.department} </Link>
                                                                    <Link to={course.programLink}>{course.programLevel}</Link>
                                                                </div>
                                                                <h3 className="kblog-text-title mb-20">
                                                                    <Link to={course.programLink}>{course.programTitle}</Link>
                                                                </h3>
                                                                <div className="kblog-text-link">
                                                                    <Link to={course.programLink}>Apply Now<i className="far fa-chevron-right"></i></Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                ))}
                                            </React.Fragment>
                                        ))}
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}



export default RelatedCourses;