import React, { useState } from 'react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, } from 'swiper';
import { EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/bundle';
import bannerBg from '../../assets/img/bg/about-bg1.jpg';



const HomeSlider = () => {


    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    return (
        <section className="slider-area fix position-relative">
            <div className="slider-active swiper-container">
                <div className="swiper-wrapper">
                    <Swiper
                        modules={[Navigation, Pagination, Scrollbar, A11y, EffectFade, Autoplay]}
                        effect="fade"
                        spaceBetween={30}
                        slidesPerView={1}
                        autoplaydisableoninteraction={"false"}
                        loop={true}
                        breakpoints={{
                            768: {
                                slidesPerView: 1
                            },
                            1200: {
                                slidesPerView: 1,
                            }
                        }}
                        // autoplay= {{
                        //     delay: 3000,
                        //     disableOnInteraction: true
                        // }}
                        pagination={{ clickable: true }}
                    >
                    <SwiperSlide>
                        <div className="single-slider swiper-slides" data-swiper-autoplay="10000" >
                            <div className="slide-bg" style={{ backgroundImage:`url(${bannerBg})`}}></div>
                            <div className="container">
                                <div className="row d-flex justify-content-between">
                                    <div className="col-lg-7 mslider mb-40">
                                        <div className="banner-board z-index">
                                            <div className="banner-spacing">
                                                <h5 className="mslider--subtitle mb-25" data-animation="fadeInUp" data-delay="0.3s">Who we are </h5>
                                                <h2 className="mslider--title mb-40" data-animation="fadeInUp" data-delay="0.5s">An AI training provider <span className="spanned-title"></span></h2>
                                                <p className="about-description">
                                                    We specialize in AI; we work with individuals, enterprises, and governments
                                                </p>
                                                <p className="about-description">
                                                    We apply latest education techniques, follow latest findings strictly and provide updated courses.
                                                    We care for alumni career gross after graduation through our Career Suite or AiStartUp™ programme.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="slide-shape shape-1"><img src={slidershape1} className="img-fluid" alt="shape-img"/></div>
                            <div className="slide-shape shape-2"><img src={slidershape2} className="img-fluid" alt="shape-img"/></div> */}
                        </div>
                    </SwiperSlide>
                    </Swiper>
                </div>
                
                <div className="slider-paginations slide-dots"></div>
            </div>
            <span className="bg-accent position-absolute bottom-75 h-6 w-50"></span>
        </section>
    );
}

export default HomeSlider;