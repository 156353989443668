import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import schoolsData from '../../../data/schoolsData.json';
import sidebarIcon1 from "../../../assets/img/icon/sidebar-icon-1.png" 
import footerIcon1 from "../../../assets/img/icon/footer-icon-1.png"
import footerIcon2 from "../../../assets/img/icon/footer-icon-2.png"

const BurgerMenus = ({setMenuOpen, menuOpen}) => {

      const [home, setHome] = useState(false)
      const [about, setAbout] = useState(false)
      const [schools, setSchools] = useState(false)
      const [enterprises, setEnterprises] = useState(false)
      const [government, setGovernment] = useState(false)
      const [research, setResearch] = useState(false)

      const location = useLocation()
      const [path, setPath] = useState("")
      useEffect(() => {
        setPath(location.pathname)
      }, [location])

      const openMobileMenu = menu => {
        
        if( menu === 'home'){
          setHome(!home)
          setAbout(false)
          setSchools(false)
          setEnterprises(false)
          setGovernment(false)
          setResearch(false)
        }
        else if( menu === 'about'){
          setHome(false)
          setAbout(!about)
          setSchools(false)
          setEnterprises(false)
          setGovernment(false)
          setResearch(false)
        }
        else if( menu === 'schools'){
          setHome(false)
          setAbout(false)
          setSchools(!schools)
          setEnterprises(false)
          setGovernment(false)
          setResearch(false)
        }
        else if( menu === 'enterprises'){
          setHome(false)
          setAbout(false)
          setSchools(false)
          setEnterprises(!enterprises)
          setGovernment(false)
          setResearch(false)
        }
        else if( menu === 'government'){
          setHome(false)
          setAbout(false)
          setSchools(false)
          setEnterprises(false)
          setResearch(false)
          setGovernment(!government)
        }
        else if( menu === 'research'){
          setHome(false)
          setAbout(false)
          setSchools(false)
          setEnterprises(false)
          setGovernment(false)
          setResearch(!research)
        }
      }; 

      return (
      <div className="fix">
        <div className={menuOpen ? "side-info info-open": "side-info"}>
            <button className="side-info-close" onClick={() => setMenuOpen(false)}><i className='far fa-times'></i></button>
              <div className="side-info-content">
                <div className="mm-menu">
                  <ul>
                    <li className={home ? "active" : ""}>
                      <Link to="/"><span onClick={() => { openMobileMenu('home'); }}>Home</span></Link>
                    </li>
                    <li><Link to="/about"><span>About</span></Link></li>
                    <li className={schools ? "has-droupdown active" : "has-droupdown"}>
                      <Link to="/schools"><span onClick={() => { openMobileMenu('schools'); }}>Schools</span></Link>
                      <ul className={schools ? "sub-menu active" : "sub-menu"}>
                        {schoolsData.map(school => (
                          <li key={school.id}><Link to={`/schools${school.schoolLink}`}>{school.schoolName}</Link></li>
                        ))}
                      </ul>
                    </li>
                    <li>
                      <Link to="/enterprises"><span onClick={() => { openMobileMenu('enterprises'); }}>For Enterprises</span></Link>
                    </li>
                    <li>
                      <Link to="/governments"><span onClick={() => { openMobileMenu('governments'); }}>For Governments</span></Link>
                    </li>
                    <li>
                      <Link to="/research"><span onClick={() => { openMobileMenu('research'); }}>Research</span></Link>
                    </li>
                    {/* <li><Link to="/admissions-office"><span>Contact</span></Link></li> */}
                  </ul>
                </div>
                <div className="contact-infos mb-30">
                    <div className="contact-list mb-30">
                        <h4>Contact Info</h4>
                        <ul>
                          <li><img src={sidebarIcon1} className="img-fluid" alt="icon-img"/>1 Wagener Place, Mt Albert, Auckland 1025, New Zealand</li>
                          <li><img src={footerIcon1} className="img-fluid" alt="icon-img"/><a href="mailto:consult@metaversityai.ai">consult@metaversityai.ai</a></li>
                          <li><img src={footerIcon2} className="img-fluid" alt="icon-img"/><a href="tel:64 22410 6020">+64 22410 6020</a></li>
                        </ul>
                        <div className="sidebar__menu--social">
                          <a href="https://www.linkedin.com/company/metaversity-of-artificial-intelligence/" target="blank" rel="noopener"><i className="fab fa-linkedin"></i></a>
                          <a href="https://twitter.com/Metaversity_AI" target="blank" rel="noopener"><i className="fab fa-twitter"></i></a>
                          <a href="https://www.facebook.com/profile.php?id=61559307292748" target="blank" rel="noopener"><i className="fab fa-facebook-f"></i></a>
                          {/* <a href="#" target="blank" rel="noopener"><i className="fab fa-pinterest-p"></i></a> */}
                          <a href="https://www.instagram.com/meatversityai/" target="blank" rel="noopener"><i className="fab fa-instagram"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
          
    )
}

export default BurgerMenus;