import React from 'react';
import { Link } from 'react-router-dom';

const FooterBottom = () => {
    return (
        <div className="copyright-area">
            <div className="container">
               <div className="copyright-bg">
                     <div className="row align-items-center">
                        <div className="col-md-4">
                           <div className="copyright d-flex inline">
                                 <p className="text-white">Copyright© {new Date().getFullYear()}. All Rights Reserved.</p>
                           </div>
                        </div>
                        <div className="col-md-3">
                           <div className="copyright d-flex inline">
                                 <p className="text-white">Crafted by {" "} </p>
                                 <a href="https://www.intellapps.io/" target="_blank" rel="noopener noreferrer"> 
                                     IntellApps™
                                 </a> 
                           </div>
                        </div>
                        <div className="col-md-5">
                           <div className="privacy-text text-center text-md-end"> 
                                 <ul>
                                    <li>
                                       <Link to="/terms-of-use"><a>Terms of Use</a></Link>
                                       <Link to="/privacy-policy"><a>Privacy Policy</a></Link>
                                    </li>
                                 </ul>
                           </div>
                        </div>
                     </div>
               </div>
            </div>
         </div>
    );
}

export default FooterBottom;