import React from 'react';
import AboutTab from '../../Components/Elements/Tab/AboutTab';
import AboutWhyWeTab from '../../Components/Elements/Tab/AboutWhyWeTab';

const WhyWeTwo = () => {

    return (
        <section className="why-we pt-125 pb-75 position-relative fix">
            <div className="common-shape-wrapper wow slideInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms">
                <div className="common-shape-inner"></div>
            </div>
            <div className="container z-index">
                <div className="row justify-content-between">
                    <div className="col-xl-6 col-lg-6 col-md-12">
                        <div className="choose-left mb-40 mr-xs-0 mr-md-0 mr-lg-0 mr-90" data-aos="fade-up-right" data-aos-duration="1000">
                            <div className="section-title-wrapper">
                                <h5 className="section-subtitle mb-20">About Us</h5>
                                <h2 className="section-title-about mb-35">Why Metaversity AI is the Premier Choice <span className="text-primary">for Specialized AI Education?</span></h2>
                            </div>
                            <p>Metaversity AI began as a platform offering individual AI development mentoring and has evolved into a premier institution for comprehensive AI education, empowering students to harness the potential of AI across diverse disciplines, from General Artificial Intelligence to specialized fields such as Business & Finance and Natural Sciences.</p>
                            <div className="about-whytablist">
                                <p><strong>'Metaversity'</strong> combines <strong>'meta'</strong>, meaning beyond, with <strong>'university'</strong>, representing our mission to provide AI education that transcends traditional academic boundaries. We achieve this by:</p>
                                <ul>
                                    <li><i className="far fa-check"></i>Quickly adapting our curriculum to the rapid advancements in the field,</li>
                                    <li><i className="far fa-check"></i>Offering comprehensive career support to students after graduation, through Career Suite and AiStartUp programs,</li>
                                    <li><i className="far fa-check"></i>Fostering a strong AI community that champions the ethical use of technology through worldwide active alumni networking.</li>
                                </ul>
                            </div>
                            <p>Our unique curriculum includes tailored AI development programs spanning critical and emerging disciplines such as AI in Cybersecurity, Medicine, Business Analytics, and more. Each program is crafted to meet the needs of both enthusiasts beginning their AI journey and professionals seeking to deepen their expertise.</p>
                            <p>We are committed to fostering a vibrant, diverse, and collaborative learning environment that not only teaches the fundamentals of AI but also encourages the practical application of skills in real-world scenarios. Join us to become part of a community that shapes the future of technology.</p>
                            <p>At Metaversity AI, we go beyond education. We are committed to your long-term success. Our alumni receive ongoing support in job placement and career guidance, ensuring a seamless transition into the AI workforce or research, and opening doors to limitless opportunities.</p>
                            {/* <h2 className="mb-35">Who we are <span className="text-primary">not?</span></h2> */}
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12">
                        <div className="choose-right mt-5" data-aos="fade-left" data-aos-duration="1000">
                            <AboutWhyWeTab/>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
    );
}

export default WhyWeTwo;