import React from 'react';
import { Link } from 'react-router-dom';
import governImg from '../../assets/img/about/gov-bg.jpg';


const WhyWeThree = () => {
    return <section className="why-we bg-grey pt-120 pb-125 pb-md-110 position-relative">
        <div className="common-shape-wrapper wow slideInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms">
            <div className="common-shape-inner wow slideInleft animated" data-wow-delay="0ms" data-wow-duration="1500ms"></div>
        </div>
        <div className="container z-index">
            <div className="row justify-content-between">
                <div className="col-xl-6 col-lg-6 col-md-12">
                <h5 className="section-subtitle mb-20">Empowering Global AI Education:</h5>
                    <h3 className="section-title mb-35">A Collaborative Journey with Governments <br /></h3>
                    <div className="mb-30" data-aos="fade-right" data-aos-duration="1000">
                        <img src={governImg} className="img-fluid" alt="" />
                        <div className="mission-img-text">
                            <h4>We’re Committed to
                                Being a Trusted Partner
                            </h4>
                            <Link to="/admissions-office"><a>Contact Us</a></Link>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 pt-40">
                    <div className="provide-content">
                        <div className="section-title-whywe sides-padding text-uppercase mb-25">
                            <h4>Trusted Partnerships with Global Governments</h4>
                        </div>
                        <p className="sides-padding">By collaborating with us, governments are enhancing their specialized programs and projects through tailored AI training initiatives aimed at developing the AI skills of their staff. Our partnerships focus on empowering these professionals to leverage AI for technological advancement, societal progress, and enhanced global cooperation.</p>
                        <p className="sides-padding">Together, we are pioneering new benchmarks in AI education by delivering tailored AI training programs across diverse scientific disciplines. Our AI development courses span a variety of scientific disciplines, ranging from AI in drug discovery and healthcare to AI in media production and in IT fields or chemistry. </p>
                    </div>
                </div>
            </div>
        </div>
    </section>;
};

export default WhyWeThree;