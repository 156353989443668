import React, { useState, useEffect, useCallback } from 'react';

const getDayIndex = (dayName) => {
  const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday"];
  return daysOfWeek.indexOf(dayName?.split(' ')[1] || dayName); // Handle "2nd Monday" etc.
};

// Helper function to calculate the nth occurrence of a day in a month
const calculateNthWeekdayOfMonth = (year, month, dayIndex, nthWeek) => {
  let date = new Date(year, month, 1);
  let count = 0; // To count the occurrence of the day

  // Loop over all the days of the month
  while (date.getMonth() === month) {
    if (date.getDay() === dayIndex) {
      count++;
      if (count === nthWeek) {
        return date;
      }
    }
    date.setDate(date.getDate() + 1);
  }

  return null; // In case the nth occurrence doesn't exist
};

const GetNextDayOfWeek = ({ startDayofWeek }) => {
  const [nextDay, setNextDay] = useState(null);

  const calculateNextDay = useCallback(() => {
    const currentDate = new Date();
    let targetDate = null;

    const parts = startDayofWeek?.split(' ');
    const nthWeek = parts.length > 1 ? parseInt(parts[0], 10) : null; // "2nd" becomes 2
    const dayName = parts.length > 1 ? parts[1] : parts[0];
    const targetDayIndex = getDayIndex(dayName);

    if (nthWeek) {
      // Handling "2nd Monday" cases
      const currentMonth = currentDate.getMonth();
      const currentYear = currentDate.getFullYear();
      let nthWeekDate = calculateNthWeekdayOfMonth(currentYear, currentMonth, targetDayIndex, nthWeek);

      if (!nthWeekDate || currentDate > nthWeekDate) {
        // If nthWeekDate is past or doesn't exist, check the 4th week or next month's 2nd week
        nthWeekDate = calculateNthWeekdayOfMonth(currentYear, currentMonth, targetDayIndex, nthWeek === 2 ? 4 : 2);

        if (!nthWeekDate || currentDate > nthWeekDate) {
          // If the date is still past, calculate for the next month's nth week
          nthWeekDate = calculateNthWeekdayOfMonth(currentYear, (currentMonth + 1) % 12, targetDayIndex, nthWeek);
        }
      }
      targetDate = nthWeekDate;
    } else {
      // Handling simple "Monday" cases
      let daysUntilNext = targetDayIndex - currentDate.getDay();
      if (daysUntilNext < 0) daysUntilNext += 7; // Ensure it's in the future
      targetDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + daysUntilNext);
    }

    setNextDay(targetDate);
  }, [startDayofWeek]);

  useEffect(() => {
    calculateNextDay();
  }, [calculateNextDay]);

  return (
    <div>
      {nextDay ? (
        <p>{nextDay.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}</p>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default GetNextDayOfWeek;
