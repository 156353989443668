import React from "react";


// MainPages
import Home from "../MainPages/Home";
import About from "../MainPages/About";
import Contact from "../MainPages/Contact";
import Research from "../MainPages/Research";
import JournalOfAIS from "../MainPages/Journal";
import Schools from "../MainPages/Schools";
import SingleSchool from "../MainPages/SingleSchool";
import Enterprises from "../MainPages/Enterprises";
import EnterpriseDetails from "../MainPages/EnterpriseDetails";
import Government from "../MainPages/Government";
import GovernmentDetails from "../MainPages/GovernmentDetails";
import Program from "../MainPages/Courses";


//pages
// import Starter from '../pages/Starter/Starter';
// import Timeline from '../pages/Timeline/Timeline';
// import Faqs from '../pages/Faqs/Faqs';
import Maintenance from '../pages/Maintenance/Maintenance';
import ComingSoon from '../pages/ComingSoon/ComingSoon';
import SiteMap from '../pages/SiteMap/SiteMap';


// Legal
import EQAS from '../pages/EQAS';
import PrivecyPolicy from '../pages/Legal/privacyPolicy';
import TermsOfUse from '../pages/Legal/termsOfUse';


const publicRoutes = [
  //MainPages
  { path: "/", component: <Home /> },
  { path: "/about", component: <About /> },
  { path: "/admissions-office", component: <Contact /> },
  { path: "/research", component: <Research /> },
  { path: "/ai-spectra", component: <JournalOfAIS /> },
  { path: "/schools", component: <Schools /> },
  { path: "/schools/:schoolLink", component: <SingleSchool /> },
  { path: "/:schoolLink/:departmentLink/:programLink", component: <Program /> },
  { path: "/enterprises", component: <Enterprises /> },
  { path: "/enterprise-details", component: <EnterpriseDetails /> },
  { path: "/governments", component: <Government /> },
  { path: "/governments-details", component: <GovernmentDetails /> },


  // Authentication Page

  { path: "/education-quality-assurance-standards", component: <EQAS /> },
  { path: "/terms-of-use", component: <TermsOfUse /> },
  { path: "/privacy-policy", component: <PrivecyPolicy /> },
  { path: "/pages-maintenance", component: <Maintenance /> },
  { path: "/pages-coming-soon", component: <ComingSoon /> },

  //Pages
  // { path: "/pages-starter", component: <Starter /> },
  // { path: "/pages-timeline", component: <Timeline /> },
  // { path: "/pages-faqs", component: <Faqs /> },
  { path: "/sitemap", component: <SiteMap /> },

];

export { publicRoutes };