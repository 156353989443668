import React, { lazy } from 'react';
// import Video from '../../Components/Elements/Video/VideoSection';
// import About from './AboutSection';
import Cta from './CtaSection';
import SchoolsSection from './SchoolsSection';
// import Trust from './TrustSection';
import Quality from './QualitySection';
// import Project from './ProjectSection';
const HomeBanner = lazy(() => import('./HomeBanner'));
// const Testimonial = lazy(() => import('../../Components/Elements/Testimonials/TestimonialsSection'));
// const Brand = lazy(() => import('../../Components/Elements/Brand/BrandSection'));
const CourseSlider = lazy(() => import('./CourseSliderSection'));


const HomeMain = () => {
	
	document.title="Metaversity AI | Metaversity of Artificial Intelligence";
	return (
		<React.Fragment>
			{/* slider-start */}
			<HomeBanner />
			{/* slider-end */}

			{/* blog-start */}
			<CourseSlider />
			{/* blog-end */}

			{/* SchoolsSection-start */}
			<SchoolsSection />
			{/* SchoolsSection-end */}

			{/* cta-start */}
			<Cta />
			{/* cta-end */}

			{/* project-start */}
			{/* <Project /> */}
			{/* project-end */}

			{/* video-start */}
			{/* <Video /> */}
			{/* video-end */}

			{/* testimonial-start */}
			{/* <Testimonial /> */}
			{/* testimonial-end */}

			{/* brand-start */}
			{/* <Brand /> */}
			{/* brand-end */}

			{/* trust-start */}
			{/* <Trust /> */}
			{/* trust-end */}

			{/* about-start */}
			{/* <About /> */}
			{/* about-end */}

			{/* quality-start */}
			<Quality />
			{/* quality-end */}

		</React.Fragment>
	);
}

export default HomeMain;