import React, { lazy } from 'react';
import GovBanner from './GovBanner';
import Quality from '../Home/QualitySection';
import ServiceFour from './ServiceSectionFour';
import ServiceSectionThree from './ServiceSectionThree';
import GovWhyWe from './GovWhyWe';
const BrandFour = lazy(() => import('../../Components/Elements/Brand/BrandSectionFour'))


const ServiceMain = () => {

    return (
        <main>
            {/* depbanner-start */}
            <GovBanner />
            {/* depbanner-end */}

             {/* whywe-start */}
             <GovWhyWe />
            {/* whywe-end */}

            {/* service-start */}
            {/* <ServiceSectionThree /> */}
            {/* service-end */}

            {/* service-start */}
            {/* <ServiceFour /> */}
            {/* service-end */}

            {/* brand-start */}
            {/* <BrandFour /> */}
            {/* brand-end */}

            {/* Quality-start */}
            <Quality />
            {/* Quality-end */}
        </main>
    );
}

export default ServiceMain;