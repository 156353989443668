/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react';
import { Modal, ModalBody, Card } from 'reactstrap';


const SupportChat = () => {

    const [isWindowDown, setIsWindowDown] = useState(false);
    function toggleWindowPosition() {
        setIsWindowDown(!isWindowDown);
    }

    window.onscroll = function () {
        scrollFunction();
    };

    const scrollFunction = () => {
        const element = document.getElementById("back-to-top");
        if (element) {
            if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
                element.style.display = "block";
            } else {
                element.style.display = "none";
            }
        }
    };

    const toTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };
    
    // open chat component
    const [isChatVisible, setIsChatVisible] = useState(false);

    // Toggle chat visibility
    const toggleChatVisibility = useCallback(() => {
        setIsChatVisible(isChatVisible => !isChatVisible); // using functional update form
    }, []);

    // Style object for iframe
    const iframeStyle = {
        border: 'none', 
        height: '600px', 
        width: '100%', // Make sure it takes the full width
        visibility: isChatVisible ? 'visible' : 'hidden',
        position: isChatVisible ? 'static' : 'absolute', // Keep it in place when visible
        left: '0', // For absolute positioning
        bottom: '0' // For absolute positioning
    };

    return (
        <React.Fragment>
            <button
                onClick={() => toTop()}
                className="btn btn-primary btn-icon" id="back-to-top">
                <i className="ri-arrow-up-line"></i>
            </button>

            <div>
                <div className="customizer-setting d-none d-md-block">
                    <div onClick={toggleChatVisibility} className="btn-primary btn-rounded shadow-lg btn btn-icon btn-lg p-2">
                        <i className='las la-comments fs-22'></i>
                    </div>
                </div>

                <Modal id="bottom-rightModal" isOpen={isChatVisible} toggle={toggleChatVisibility} className="supportchat-modal">
                    <ModalBody className="text-center">
                        <Card className="card-height-500">
                       
                                <iframe 
                                    id="iframe-id"
                                    src="https://www.externchat.metaversityai.ai" 
                                    title="Chat" 
                                    style={iframeStyle}>
                                </iframe>

                        </Card>
                    </ModalBody>
                </Modal>
            </div>
        </React.Fragment>
    );
};

export default SupportChat;