import React from 'react';
import EnpriceWhyWe from './EnpriceWhyWe';
import EqasBanner from './EqasBanner';


const ArticleMain = () => {

    return (
        <main>
            {/* JournalBanner-start */}
            <EqasBanner pageTitle="Blog" />
            {/* JournalBanner-end */}
             {/* whywe-start */}
             <EnpriceWhyWe />
            {/* whywe-end */}
        </main>
    );
}

export default ArticleMain;