import React, { useState, useEffect } from 'react';
import { CardBody, Row, Col } from 'reactstrap';
import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-light.css'; // Choose the style you prefer


const SectionText = ({ textToRender }) => {
  // Initialize isCopied as an empty object to keep track of the copied state of each button
  const [copiedIndices, setCopiedIndices] = useState({});

  useEffect(() => {
    document.querySelectorAll('pre code:not([data-highlighted])').forEach(block => {
      hljs.highlightElement(block); // Updated to use highlightElement
      block.setAttribute('data-highlighted', 'yes');
    });
  }, [textToRender]);  

  const copyToClipboard = (text, index) => {
    navigator.clipboard.writeText(text);
    // Set the copied state for the specific button that was clicked
    setCopiedIndices(prev => ({ ...prev, [index]: true }));
    // Reset the copied state for that button after 2 seconds
    setTimeout(() => {
      setCopiedIndices(prev => ({ ...prev, [index]: false }));
    }, 2000);
  };

  const renderContent = () => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(textToRender || '', 'text/html');
    const nodes = Array.from(doc.body.childNodes);
  
    return nodes.map((node, index) => {
      let additionalProps = {};
      if (node.nodeName === 'PRE' && node.firstChild) {
        // Add a unique identifier to each code block, e.g., using the index
        additionalProps['data-code-index'] = index;
      }
      return (
        <div key={index} {...additionalProps} className={node.nodeName === 'PRE' ? "code-block-container hljs selectable quill-highlighter" : "simple-text"}>
          {node.nodeName === 'PRE' ? (
            <>
              <pre>
                <code>
                  {node.textContent}
                </code>
              </pre>
              <button onClick={() => copyToClipboard(node.textContent, index)} className="copy-code-button">
                {copiedIndices[index] ? 'Copied!' : <i className="ri-file-copy-line"></i>}
              </button>
            </>
          ) : (
            <div dangerouslySetInnerHTML={{ __html: node.outerHTML }} />
          )}
        </div>
      );
    });
  };
  

  return (
    <CardBody>
      <Row>
        <Col lg={12}>
          <div className="mb-3 simple-text">
            {renderContent()}
          </div>
        </Col>
      </Row>
    </CardBody>
  );
};

export default SectionText;