/* eslint-disable no-undef */
import React, { useState } from "react";
import { Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { Link } from "react-router-dom";
import pricing  from '../../data/pricingData';
import singlePrice  from '../../data/singlePricing';



const FeesSection = ({ program }) => {
    const [activeTab, setActiveTab] = useState('1');
    
    if (!program) {
        return <div>Loading ...</div>;
    }
    
    const getDescription = `This plan includes a single program - the selected "${program.programTitle}" program. It is an excellent choice for advancing skills in this specialized AI domain.`
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    return (
        <React.Fragment>
            <div id="pricing-section" className="page-content">
                <Container fluid className="pricing-spacing">
                    <Row>
                        <Col xl={12}>
                            <Row className="justify-content-center row-spacing">
                                <Col lg={5}>
                                    <div className="text-center mb-4">
                                        <h4 className="fees-title">Choose a Plan</h4>
                                        <p className="text-muted mb-4 fs-15">Advanced options for your success.</p>
                                        <div className="d-inline-flex">
                                            <Nav className="nav-pills arrow-navtabs plan-nav rounded mb-3 p-1 gap-1" id="pills-tab" role="tablist">
                                                <NavItem className="navitem-settings">
                                                    <NavLink
                                                        href="#"
                                                        className={classnames({ active: activeTab === '1' }, 'fw-semibold')}
                                                        onClick={() => { toggleTab('1'); }}
                                                    >
                                                        Comprehensive Plans 
                                                    </NavLink>
                                                    {/* <span className="badge bg-success">Save 10%</span> */}
                                                </NavItem>
                                                <NavItem className="navitem-settings">
                                                    <NavLink
                                                        href="#"
                                                        className={classnames({ active: activeTab === '2' }, 'fw-semibold')}
                                                        onClick={() => { toggleTab('2'); }}
                                                    > Monthly Plans
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="ms-auto">
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="1">
                                        <Row className="row-spacing">
                                            <Col xxl={3} lg={6}>
                                                <Card className="mb-3 pricing-box">
                                                    <CardBody className="bg-light m-2 pt-5 px-3">
                                                        <div className="flex-grow-1 text-center mb-3">
                                                            <h4 className="mb-0 fw-semibold text-dark">{singlePrice[1].type}</h4>
                                                        </div>
                                                        <div className="d-flex d-block align-items-center mb-5">
                                                            <div className="ms-auto">
                                                                <h2 className="month mb-0 ms-3 me-5 text-primary">
                                                                    ${singlePrice[1].rate}
                                                                    {singlePrice[1].type === "Pay as-you-go" ? <small className="fs-12">/month</small> : ""}
                                                                </h2>
                                                            </div>
                                                        </div>

                                                        <p className="text-muted mb-5">{getDescription}</p>
                                                        <div className="text-divider mb-5">Selected Program</div>
                                                        <ul className="mt-3 mb-5 list-unstyled vstack gap-3">
                                                            <li>
                                                                <div className="d-flex">
                                                                    <div className="flex-grow-1 text-break programTitle-text">
                                                                        {program?.programTitle}
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                        <div className="mt-5 pt-2">
                                                            <Link
                                                                to={`https://www.metacenter.metaversityai.ai/login?redirectUrl=${encodeURIComponent(`/enrollment-page/${program?.id}?priceId=${singlePrice[1].id}`)}`}
                                                                // to={`http://localhost:3000/login?redirectUrl=${encodeURIComponent(`/enrollment-page/${program?.id}?priceId=${singlePrice[1].id}`)}`}
                                                                className={`btn btn-${singlePrice[1].planButtonClassname} ${singlePrice[1].btnstatus} w-100`}
                                                                rel="noopener noreferrer"
                                                            >
                                                                {singlePrice[1].btntxt}
                                                            </Link>
                                                        </div>

                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            {(pricing || []).map((price, key) => (
                                                <Col xxl={3} lg={6} key={key}>                                                    
                                                    <Card className={`mb-3 pricing-box ribbon-box right ${price.ribbon ? `ribbon-${price.ribbon}` : ""}`}>
                                                        {
                                                            price.ribbon === "cheap" ? (
                                                                <div className="ribbon-two ribbon-two-danger"><span>Economical</span></div>
                                                            ) : price.ribbon === "scholar" ? (
                                                                <div className="ribbon fs-16 ribbon-primary text-center">With <br/> Scholarship (limited)</div>
                                                            ) : price.ribbon === "popular" ? (
                                                                <div className="ribbon fs-16 ribbon-success text-center">Most Chosen</div>
                                                            ) : ""
                                                        }
                                                        <CardBody className="bg-light m-2 pt-5 px-3">
                                                            <div className="d-flex align-items-center mb-3">
                                                                <div className="flex-grow-1">
                                                                    <h5 className="mb-0 fw-semibold">{price.type}</h5>
                                                                </div>
                                                                <div className="ms-auto">
                                                                    <h2 className="month mb-0 ms-3 me-5 text-primary">
                                                                        ${price.rate}
                                                                        {price.type === "Pay as-you-go" ? <small className="fs-12 text-muted">/month</small> : ""}
                                                                    </h2>
                                                                </div>
                                                            </div>

                                                            <p className="text-muted mb-3">{price.description}</p>
                                                            <div className="text-divider">Program Package</div>
                                                            <ul className="mt-3 list-unstyled vstack gap-3">
                                                                <li>
                                                                    <div className="d-flex">
                                                                        <div className="flex-shrink-0 text-success me-1">
                                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                                        </div>
                                                                        <div className="flex-grow-1">
                                                                            {/* <b>{price.projects}</b> Projects */}
                                                                            <b>{price.item1}</b>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="d-flex">
                                                                        <div className="flex-shrink-0 text-success me-1">
                                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                                        </div>
                                                                        <div className="flex-grow-1">
                                                                            <b>{price.item2}</b>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="d-flex">
                                                                        <div className="flex-shrink-0 text-success me-1">
                                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                                        </div>
                                                                        <div className="flex-grow-1">
                                                                            <b>{price.item3} *</b>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li><div className="flex-grow-1 ps-4 text-dark">
                                                                            Specialization Program: 
                                                                        </div>
                                                                    <div className="d-flex">
                                                                        <div className="flex-shrink-0 text-success me-1">
                                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                                        </div>
                                                                        <div className="flex-grow-1">
                                                                            <b className="text-primary">{program?.programTitle}</b>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <div className="text-divider">+</div>
                                                                <li>
                                                                    <div className="d-flex mb-3">
                                                                        <div className="flex-shrink-0 text-success me-1">
                                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                                        </div>
                                                                        <div className="flex-grow-1">
                                                                            <b>{price.item4}</b>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <div className="text-divider">Career Support</div>
                                                                <li>
                                                                    <div className="d-flex mt-3">
                                                                        <div className={`flex-shrink-0 text-${price.supportClass} me-1`}>
                                                                            <i className={`ri-${price.supportSymbol}-circle-fill fs-15 align-middle`}></i>
                                                                        </div>
                                                                        <div className="flex-grow-1">
                                                                        <b>{price.item5} **</b>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="d-flex">
                                                                        <div className={`flex-shrink-0 text-${price.supportClass} me-1`}>
                                                                            <i className={`ri-${price.supportSymbol}-circle-fill fs-15 align-middle`}></i>
                                                                        </div>
                                                                        <div className="flex-grow-1">
                                                                            {price.item6} ***
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                            <div className="mt-3 pt-2">
                                                                <Link
                                                                    to={`https://www.metacenter.metaversityai.ai/login?redirectUrl=${encodeURIComponent(`/enrollment-page/${program?.id}?priceId=${price.id}`)}`}
                                                                    // to={`http://localhost:3000/login?redirectUrl=${encodeURIComponent(`/enrollment-page/${program?.id}?priceId=${price.id}`)}`}
                                                                    className={`btn btn-${price.planButtonClassname} ${price.btnstatus} w-100 btntxt-setting`}
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    {price.btntxt}
                                                                </Link>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            ))}
                                        </Row>
                                        <Row className="row-spacing mt-4">
                                            <ul className="list-unstyled vstack">
                                                <li className="ms-4 text-muted d-flex display-block flex-grow-1">
                                                    <p><b className="text-dark">*</b> The Deep Learning program applies to most AI specializations we offer.</p>
                                                </li>
                                                <li className="ms-4 text-muted d-flex display-block flex-grow-1">
                                                    <p><b className="text-dark">**</b> We support your employment through our collaborating enterprises of <b className="text-primary"><Link to="/">CareerSuite</Link></b> project or we support your AI business startup through our <b className="text-primary"><Link to="/">AiStartUp™</Link></b> branch. We also offer additional learning programs tailored to your job requirements or AI startup project. Please contact us to discuss these support details.</p> 
                                                </li>
                                                
                                                <li className="ms-4 text-muted d-flex display-block flex-grow-1">
                                                    <p><b className="text-dark">***</b> Our CareerSuite project offers a flexible internship that is optional and can be conducted online or onsite.</p> 
                                                </li>
                                            </ul>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <Row className="row-spacing d-flex justify-content-center">
                                            <Col xxl={3} lg={6}>
                                                <Card className="mb-3 pricing-box">
                                                    <CardBody className="bg-light m-2 pt-5 px-3">
                                                        <div className="flex-grow-1 text-center mb-3">
                                                            <h3 className="mb-0 fw-semibold text-dark">{singlePrice[0].type}</h3>
                                                        </div>
                                                        <div className="d-flex d-block align-items-center mb-5">
                                                            <div className="ms-auto">
                                                                <h2 className="month mb-0 ms-3 me-5 text-primary">
                                                                    ${singlePrice[0].rate}
                                                                    {singlePrice[0].type === "Pay as-you-go" ? <small className="fs-12">/month</small> : ""}
                                                                </h2>
                                                            </div>
                                                        </div>

                                                        <p className="text-body mb-5">{singlePrice[0].description}</p>
                                                        <div className="text-divider mb-5">Selected Program</div>
                                                        <ul className="mt-3 mb-5 list-unstyled vstack gap-3">
                                                            <li>
                                                                <div className="d-flex">
                                                                    <div className="flex-grow-1 text-break programTitle-text">
                                                                        {program?.programTitle}
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                        <div className="mt-5 pt-2">
                                                            <Link
                                                                to={`https://www.metacenter.metaversityai.ai/login?redirectUrl=${encodeURIComponent(`/enrollment-page/${program?.id}?priceId=${singlePrice[0].id}`)}`}
                                                                // to={`http://localhost:3000/login?redirectUrl=${encodeURIComponent(`/enrollment-page/${program?.id}?priceId=${singlePrice[0].id}`)}`}
                                                                className={`btn btn-${singlePrice[0].planButtonClassname} ${singlePrice[0].btnstatus} w-100 btntxt-setting`}
                                                                rel="noopener noreferrer"
                                                            >
                                                                {singlePrice[0].btntxt}
                                                            </Link>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row className="row-spacing mt-4">
                                            <ul className="list-unstyled vstack text-center">
                                                <li className="ms-4 text-muted d-flex display-block justify-content-center flex-grow-1">
                                                    <p><b className="text-dark">*</b> Upon completing this program, you will receive a certificate recognized by numerous leading AI companies worldwide.</p>
                                                </li>
                                                {/* <li className="ms-4 text-muted d-flex display-block flex-grow-1">
                                                    <p><b className="text-dark">**</b> We support your ...</p> 
                                                </li>
                                                
                                                <li className="ms-4 text-muted d-flex display-block flex-grow-1">
                                                    <p><b className="text-dark">***</b> Our CareerSuite project ...</p> 
                                                </li> */}
                                            </ul>
                                        </Row>
                                    </TabPane>
                                </TabContent>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default FeesSection;
