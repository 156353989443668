import React from 'react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, } from 'swiper';
import { EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/bundle';
import bannerBg from '../../assets/img/bg/terms.png';




const EqasSlider = () => {

    return (
        <section className="slider-area fix position-relative">
            <div className="slider-active swiper-container">
                <div className="swiper-wrapper">
                    <Swiper
                        modules={[Navigation, Pagination, Scrollbar, A11y, EffectFade, Autoplay]}
                        effect="fade"
                        spaceBetween={30}
                        slidesPerView={1}
                        autoplaydisableoninteraction={"false"}
                        loop={true}
                        breakpoints={{
                            768: {
                                slidesPerView: 1
                            },
                            1200: {
                                slidesPerView: 1,
                            }
                        }}
                        // autoplay= {{
                        //     delay: 3000,
                        //     disableOnInteraction: true
                        // }}
                        pagination={{ clickable: true }}
                    >
                    <SwiperSlide>
                        <div className="single-slider swiper-slides" data-swiper-autoplay="10000" >
                            <div className="slide-bg" style={{ backgroundImage:`url(${bannerBg})`}}></div>
                            <div className="container">
                                <div className="row d-flex justify-content-between">
                                    <div className="col-lg-6">
                                        <div className="mslider z-index">
                                            {/* <h5 className="mslider--subtitle mb-25" data-animation="fadeInUp" data-delay="0.3s">Who we are </h5> */}
                                            <h2 className="mslider--title mb-60 mt-40" data-animation="fadeInUp" data-delay="0.5s">Legal / Policies<span className="spanned-title"></span></h2>
                                            {/* <p className="description">
                                                We specialize in AI, we work with individuals and with enterprises.
                                            </p>
                                            <p className="description">
                                                We apply latest education techniques, we provide updated courses and 
                                                we care for alumni career gross after graduation through our Industry Suit programme.
                                            </p> */}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="about-banner-right mb-150 mt-200" data-aos="zoom-out-down" data-swiper-autoplay="10000">
                                            <div className="section-title-wrapper">
                                                {/* <h5 className="mslider--subtitle mb-25" data-animation="fadeInUp" data-delay="0.3s">Where you will be?</h5>
                                                <h2 className="about--title mb-40" data-animation="fadeInUp" data-delay="0.5s"><span className="spanned-title">UAI is with you through </span>Industry Suit Program</h2>
                                                <p className="description"> At UAI, we go beyond education. We are committed to your long-term success. We support you with:</p> */}
                                            </div>
                                            
                                            <div className="why-tab-list mb-50">
                                                <ul>
                                                    {/* <li>AI study with trending techniques,</li>
                                                    <li>optional 3-months AI bootcamp/industrial experience</li>
                                                    <li>guarante job after graduation or </li>
                                                    <li>complete support for your own AI project</li> */}
                                                </ul>
                                            </div>
                                            {/* <div className="kabout-btn">
                                                <Link to="/industry-suit"><a className="theme-btn border-btn">Industry Suit Program</a></Link>
                                            </div> */}
                                        </div>
                                    </div>                                    
                                </div>
                            </div>
                            {/* <div className="slide-shape shape-1"><img src={slidershape1} className="img-fluid" alt="shape-img"/></div>
                            <div className="slide-shape shape-2"><img src={slidershape2} className="img-fluid" alt="shape-img"/></div> */}
                        </div>
                    </SwiperSlide>
                    </Swiper>
                </div>
                
                <div className="slider-paginations slide-dots"></div>
            </div>
            <span className="bg-accent position-absolute bottom-75 h-6 w-50"></span>
        </section>
    );
}

export default EqasSlider;