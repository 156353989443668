import React, { useState, useEffect, useRef } from 'react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, } from 'swiper';
import { EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/bundle';
import genaidepBg from '../../assets/img/bg/genaidepbg.jpg';



const HomeSlider = ({school}) => {

    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    return (
        <section className="slider-area fix position-relative">
            <div className="slider-active swiper-container">
                <div className="swiper-wrapper">
                    <Swiper
                        modules={[Navigation, Pagination, Scrollbar, A11y, EffectFade, Autoplay]}
                        effect="fade"
                        spaceBetween={30}
                        slidesPerView={1}
                        autoplaydisableoninteraction={"false"}
                        loop={true}
                        breakpoints={{
                            768: {
                                slidesPerView: 1
                            },
                            1200: {
                                slidesPerView: 1,
                            }
                        }}
                        // autoplay= {{
                        //     delay: 3000,
                        //     disableOnInteraction: true
                        // }}
                        pagination={{ clickable: true }}
                    >
                    <SwiperSlide>
                        <div className="single-slider swiper-slides" data-swiper-autoplay="10000" >
                            <div className="slide-bg" style={{ backgroundImage:`url(${genaidepBg})`}}></div>
                            <div className="container">
                                <div className="row d-flex justify-content-between">
                                    <div className="col-lg-6">
                                        <div className="mslider z-index">
                                            <h5 className="mslider--subtitle mb-25" data-animation="fadeInUp" data-delay="0.3s">School of </h5>
                                            <h2 className="mslider--title mb-40" data-animation="fadeInUp" data-delay="0.5s">{school.schoolName} </h2>
                                            <p className="description">
                                                We specialize in AI; we work with individuals, enterprises, and governments.
                                            </p>
                                            <p className="description mb-150">
                                                Select your AI specialization program from the departments listed below. Each program comes with supporting programs when you opt for package plans.
                                            </p>
                                        </div>
                                    </div>
                                                         
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    </Swiper>
                </div>
            </div>
            <span className="bg-accent position-absolute bottom-75 h-6 w-50"></span>
        </section>
    );
}

export default HomeSlider;