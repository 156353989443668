import React from "react";
import { Link, NavLink } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import TermsBanner from './TermsBanner';

const TermsCondition = () => {

  return (
    <React.Fragment>
      {/* JournalBanner-start */}
      <TermsBanner pageTitle="Blog" />
      {/* JournalBanner-end */}
      <div className="page-content py-5">
        <Container fluid>
          <Row className="justify-content-center">
            <Col lg={10}>
              <Card>
                <div className="bg-soft-info mt-5 position-relative">
                  <CardBody className="card-body mt-5 p-5">
                    <div className="text-center">
                      <h1>Term of Use</h1>
                      <p className="mb-0 text-muted">
                        Last update: 4 July, 2024  <span className="ms-2">v100106</span>
                      </p>
                    </div>
                  </CardBody>
                </div>
                <CardBody className="p-4">
                  <div>
                    <h5>Welcome to Metaversity of Artificial Intelligence!</h5>
                  </div>

                  <div>
                    <title>
                    </title>
                    <p>
                      <strong>&nbsp;</strong>
                    </p>
                    <p>
                      Metaversity AI, operated by Metaversity of Artificial Intelligence (referred to as "we," "us," or "Metaversity AI"), provides access to our online course services (referred to as the "Services") subject to the terms and conditions outlined in this document (referred to as the "Terms of Use"). These Terms of Use, along with our Privacy Policy, govern your use of Metaversity AI's platform and must be acknowledged and agreed upon before using the Services.
                    </p>
                    <p>
                      By accessing the Services, clicking "I Agree," registering a user account, or purchasing an online course, you acknowledge your acceptance of these Terms of Use. If you do not agree to these terms, please refrain from using the Services. If you are under the age of 18, but at least 13 years old, you affirm that you are an emancipated minor or have obtained appropriate parental or guardian consent to use the Services.
                    </p>
                    <p>
                      Please note that specific terms and conditions may apply if your enrollment is paid for by your employer or if you have applied to or enrolled through a scholarship program. In such cases, you are advised to refer to the corresponding terms and agreements provided.
                    </p>
                    <p>
                      Furthermore, it is important to recognize that our Services may utilize third-party services or APIs. By using Metaversity AI, you acknowledge and agree to abide by the terms and policies of these third-party services as applicable.
                    </p>
                    <p>
                      We kindly remind you that dispute resolution procedures, including arbitration, may be enforced as outlined in these Terms of Use. Please carefully review the corresponding section to understand the implications and how they may affect your rights.
                    </p>
                    <p>
                      In addition to these Terms of Use, certain components of Metaversity AI, such as specific course programs, may have special terms that govern your participation. These special terms will be provided separately and may override any conflicting provisions stated in these general Terms of Use.
                    </p>
                    <p>
                      Lastly, your participation in Metaversity AI's online courses or use of the Services subjects you to our Honor Code and Code of Community Conduct. These policies, integral to maintaining a respectful learning environment, are incorporated into these Terms of Use.
                    </p>
                    <p>
                      We encourage you to read these Terms of Use thoroughly, as they form a legally binding agreement between you and Metaversity AI. By utilizing our online courses or accessing our Services, you signify your understanding and acceptance of these terms.
                    </p>
                    <p style={{lineHeight: '108%', fontSize: '12pt'}}>
                      <strong>&nbsp;</strong>
                    </p>
                    <p style={{lineHeight: '108%', fontSize: '14pt'}}>
                      <strong>Table of Contents</strong>
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      1. Services Offered
                    </p>
                    <p>
                      2. Accounts
                    </p>
                    <p>
                      3. Privacy Policy
                    </p>
                    <p>
                      4. Changes to the Terms of Service
                    </p>
                    <p>
                      5. User Behavior and Conduct Guidelines
                    </p>
                    <p>
                      6. Granting a License to Use the Services
                    </p>
                    <p>
                      7. License to Educational Content
                    </p>
                    <p>
                      8. Intellectual Property
                    </p>
                    <p>
                      9. Interactions in Discussion Forums, Chatrooms, and Assistance Chat
                    </p>
                    <p>
                      10. Online Courses Provided by Educational Partners
                    </p>
                    <p>
                      11. Enterprise Sponsored and Scholarship Supported Services
                    </p>
                    <p>
                      12. Payment Terms and Conditions
                    </p>
                    <p>
                      13. Cancellation and Refund Policy
                    </p>
                    <p>
                      14. Availability of Services and Limitations
                    </p>
                    <p>
                      15. Disclaimer of Warranties
                    </p>
                    <p>
                      16. Limitation of Liability
                    </p>
                    <p>
                      17. Indemnification
                    </p>
                    <p>
                      18. Termination of User Rights
                    </p>
                    <p>
                      19. Trade Regulations and Compliance
                    </p>
                    <p>
                      20. Procedure for Copyright Infringement Claims and Disputes
                    </p>
                    <p>
                      21. Arbitration Clause
                    </p>
                    <p>
                      22. Additional Provisions and Miscellaneous Clauses
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <h4>
                      1. Services Offered
                    </h4>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      At Metaversity AI, we offer a comprehensive range of online courses and educational services (collectively referred to as the "Services") designed to enhance your AI knowledge and skills. Our Services are provided through our website and other digital platforms. By accessing and using our Services, you agree to comply with these Terms of Use.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      1.1 Course Offerings
                    </p>
                    <p>
                      We provide a diverse selection of online courses taught by experienced instructors, industry professionals, and educational partners. Our course catalog covers various disciplines, including but not limited to technology, business, data science, programming, design, and more. These courses are designed to be self-paced, allowing you to learn at your convenience.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      1.2 Course Materials and Resources
                    </p>
                    <p>
                      As part of our Services, we offer course materials and resources that may include video lectures, presentations, readings, quizzes, assignments, coding exercises, and interactive learning tools. These materials are intended to facilitate your learning experience and provide you with the necessary resources to succeed in your chosen course.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      1.3 Educational Support
                    </p>
                    <p>
                      We strive to support your learning journey by offering educational support through various channels. This may include discussion forums, chatrooms, or other communication platforms where you can interact with instructors, mentors, and fellow learners. Additionally, we may provide supplemental resources such as study guides, tutorials, and webinars to enhance your understanding of the course material.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      1.4 Certification and Credentialing
                    </p>
                    <p>
                      Upon successful completion of a course, you may be eligible to receive a certificate or credential that verifies your achievement. The specific requirements for certification and credentialing may vary depending on the course and the issuing institution or organization. Please refer to the course details for more information on certification options.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      1.5 Updates and Enhancements
                    </p>
                    <p>
                      We are committed to continuously improving our Services to provide you with the best learning experience. This may involve periodic updates, enhancements, or modifications to our course offerings, course materials, or platform features. We will strive to notify you of any significant changes that may impact your access to or use of the Services.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      1.6 Third-Party Services
                    </p>
                    <p>
                      In some cases, we may collaborate with educational partners or integrate third-party services into our platform to supplement our course offerings or enhance the learning experience. These third-party services may have their own terms and conditions, which you will need to review and comply with when using them in conjunction with our Services.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      1.7 Technical Requirements
                    </p>
                    <p>
                      To access and fully utilize our Services, you will need a compatible device (e.g., computer, tablet, or smartphone) with an internet connection. Certain courses may have specific technical requirements, such as software installations or hardware specifications, which will be outlined in the course details. It is your responsibility to ensure that your device meets these requirements.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      1.8 Limitations and Restrictions
                    </p>
                    <p>
                      While we strive to provide uninterrupted access to our Services, there may be occasions where service disruptions, maintenance, or technical issues occur. We will make reasonable efforts to minimize such occurrences and address any issues promptly. However, we cannot guarantee uninterrupted or error-free access to our Services at all times.
                    </p>
                    <p>
                      By using our Services, you acknowledge and agree to comply with our all applicable laws and regulations including Honor Code.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <h4>
                      2. Accounts
                    </h4>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      2.1 Account Creation
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      To access certain features and services provided by Metaversity AI, you may need to create an account. Account creation is available to individuals who meet the eligibility criteria outlined in our Terms of Use. By creating an account, you acknowledge and agree to comply with these Terms of Use and any applicable laws and regulations.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      2.2 Account Types
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      Metaversity AI offers different types of accounts tailored to various user roles and functionalities. These account types may include:
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      2.2.1 Student Accounts
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      Student accounts are created by individuals seeking to access and participate in our online courses. As a student, you will have access to course materials, assignments, assessments, and other relevant features and services provided by Metaversity AI.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      2.2.2 Instructor Accounts
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      Instructor accounts are created by qualified educators, industry professionals, and subject matter experts who wish to contribute to our course offerings. As an instructor, you may have the ability to create and publish course materials, interact with students, evaluate assignments, and perform other instructional activities.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      2.2.3 Reviewer Accounts
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      Reviewer accounts are created for individuals who assist in reviewing and evaluating student-generated content, such as project submissions or forum discussions. Reviewers play an important role in providing feedback and maintaining the quality standards of our courses.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      2.2.4 Administrator Accounts
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      Administrator accounts are reserved for authorized personnel within Metaversity AI. These accounts have administrative privileges and are responsible for managing the platform, user accounts, and ensuring the smooth operation of our services.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      2.3 Account Information
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      When creating an account, you will be required to provide accurate and complete information, including but not limited to your full name, email address, and password. It is your responsibility to maintain the confidentiality of your account credentials and promptly update any changes to your account information.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      2.4 Account Usage and Responsibilities
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      By creating an account with Metaversity AI, you agree to the following:
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      2.4.1 Account Security
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      You are solely responsible for maintaining the security of your account. You must not share your account credentials with others or allow unauthorized access to your account. If you suspect any unauthorized activity, you must notify us immediately.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      2.4.2 Account Usage Restrictions
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      You agree to use your account for lawful purposes and in compliance with these Terms of Use. You must not engage in any activities that may violate applicable laws, infringe upon intellectual property rights, or disrupt the functioning of Metaversity AI's platform or services.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      2.4.3 User-Generated Content
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      As a user of Metaversity AI's services, you may have the ability to create and contribute user-generated content. You retain ownership of your content, but by submitting it to our platform, you grant us a non-exclusive, worldwide, royalty-free license to use, modify, reproduce, distribute, and display your content for the purposes of providing our services.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      2.5 Account Termination
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      Metaversity AI reserves the right to terminate or suspend your account at any time and for any reason, including but not limited to violation of these Terms of Use or engaging in conduct that may harm our community, services, or reputation. In the event of account termination, you may lose access to course materials, progress, and other related data.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      2.6 Account Deactivation and Data Retention
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      If you wish to deactivate your account or request the deletion of your personal data, please refer to our Privacy Policy for more information on the process and the retention of data.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      2.7 Account Support
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      For account-related inquiries or support, please contact our customer support team through the designated channels provided on our website or platform.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt', lineHeight: '108%', fontSize: '14pt'}}>
                      <strong>&nbsp;</strong>
                    </p>
                    <h4>
                      3. Privacy Policy
                    </h4>
                    <p style={{marginBottom: '0pt', lineHeight: '108%', fontSize: '14pt'}}>
                      <strong>&nbsp;</strong>
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      Metaversity AI is committed to protecting your privacy and safeguarding the personal information you provide while using our services. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information in accordance with New Zealand and international privacy laws. By using our services, you consent to the practices described in this Privacy Policy.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      3.1 Collection of Personal Information
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      We may collect personal information from you when you create an account, enroll in courses, participate in discussions, submit assignments, communicate with us, or otherwise interact with our platform. The types of personal information we may collect include but are not limited to:
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      - Your name, email address, and contact details.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      - Your profile information and preferences.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      - Information related to your educational background and interests.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      - Payment and billing information for course enrollments and purchases.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      - Communications and interactions you have with instructors, mentors, or other users.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      We collect this information for the purpose of providing and improving our services, personalizing your learning experience, facilitating communication, processing payments, and complying with legal obligations.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      3.2 Use of Personal Information
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      We may use your personal information for the following purposes:
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      - To provide and administer our services, including enrollment in courses, access to course materials, and communication with instructors and other users.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      - To personalize and improve your learning experience, such as suggesting relevant courses or content.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      - To communicate with you regarding your account, course updates, notifications, and promotional offers.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      - To process payments, verify transactions, and prevent fraudulent activities.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      - To conduct research and analysis to enhance our services, develop new features, and improve user satisfaction.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      - To comply with applicable laws, regulations, or legal processes.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      - To protect the rights, property, or safety of Metaversity AI, our users, or the public.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      3.3 Disclosure of Personal Information
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      We may disclose your personal information to the following parties:
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      - Instructors and mentors: We may share relevant information with instructors and mentors to facilitate your learning experience, provide feedback, and support your educational journey.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      - Educational partners: In cases where we collaborate with educational partners to deliver courses, we may share certain personal information with them for administrative and instructional purposes.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      - Service providers: We may engage third-party service providers who assist us in delivering our services, such as hosting platforms, payment processors, customer support, and data analysis. These service providers are contractually obligated to protect your personal information and can only use it for the specific purposes we outline.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      - Legal and regulatory authorities: We may disclose your personal information in response to lawful requests, court orders, or legal processes to comply with applicable laws and regulations.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      - With your consent: We may share your personal information with other third parties if you provide us with your explicit consent.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      3.4 Data Security
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. We employ administrative, technical, and physical safeguards to maintain the confidentiality, integrity, and availability of your data. However, no method of data transmission or storage is entirely secure, and we cannot guarantee absolute security.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      3.5 Data Retention
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      We will retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, comply with legal obligations, resolve disputes, and enforce our agreements. When personal information is no longer needed, we will securely delete or anonymize it.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      3.6 International Data Transfers
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      As Metaversity AI operates globally, your personal information may be transferred and stored in countries outside of New Zealand. These countries may have different data protection laws than your own. We will ensure that appropriate safeguards are in place to protect your personal information when transferred to such countries.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      3.7 Your Rights and Choices
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      You have certain rights regarding your personal information, including the right to:
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      - Access and request a copy of your personal information held by Metaversity AI.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      - Rectify or update your personal information if it is inaccurate or incomplete.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      - Request the erasure of your personal information under certain circumstances.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      - Restrict or object to the processing of your personal information in specific situations.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      - Withdraw your consent for the processing of your personal information.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      - Receive your personal information in a structured, commonly used, and machine-readable format.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      To exercise these rights or make any inquiries regarding your personal information, please contact us using the information provided in the "Contact Us" section below. We will respond to your requests and inquiries in accordance with applicable data protection laws.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      3.8 Third-Party Websites and Services
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      Our services may contain links to third-party websites, applications, or services that are not controlled or operated by Metaversity AI. This Privacy Policy does not apply to those third-party platforms. We encourage you to review the privacy policies of any third-party websites or services you interact with.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      3.9 Updates to the Privacy Policy
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated Privacy Policy on our website or through other means of communication. We encourage you to review this Privacy Policy periodically to stay informed about how we collect, use, and protect your personal information.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      3.10 Contact Us
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      If you have any questions, concerns, or requests regarding this Privacy Policy or the privacy practices of Metaversity AI, please contact us using the following information:
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt', lineHeight: '108%', fontSize: '10pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt', lineHeight: '108%', fontSize: '10pt'}}>
                      Metaversity AI Ltd. 
                    </p>
                    <p style={{marginBottom: '0pt', lineHeight: '108%', fontSize: '10pt'}}>
                      1 Wagener Place, Mount Albert, Auckland 1025
                    </p>
                    <p style={{marginBottom: '0pt', lineHeight: '108%', fontSize: '10pt'}}>
                      <a href="/">info@metaversityai.ai</a>
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt', lineHeight: '108%', fontSize: '14pt'}}>
                      <strong>&nbsp;</strong>
                    </p>
                    <h4>
                      4. Changes to the Terms of Service
                    </h4>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      Metaversity AI reserves the right to modify or update these Terms of Service at any time. Changes may be necessary to reflect new features, improvements, legal requirements, or for other reasons deemed necessary by the Company. We encourage you to review the Terms of Service periodically to stay informed about any updates. By continuing to use our services after changes have been made, you acknowledge and agree to be bound by the revised Terms of Service.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      4.1 Notification of Changes
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      We will make reasonable efforts to notify you of any material changes to the Terms of Service. This may include sending an email to the email address associated with your account, displaying a prominent notice on our website or platform, or utilizing other means of communication. It is your responsibility to ensure that the contact information in your account is accurate and up to date.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      4.2 Acceptance of Changes
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      When we notify you of changes to the Terms of Service, you will be provided with the opportunity to review the revised terms. If you disagree with any modifications, you may choose to discontinue using our services. However, if you continue to use our services after the effective date of the changes, your continued use will constitute your acceptance of the revised Terms of Service.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      4.3 Applicable Laws and Regulations
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      Any changes made to the Terms of Service will comply with applicable laws and regulations, including those of New Zealand and international jurisdictions. We will make reasonable efforts to ensure that the revised terms are in accordance with legal requirements. If any provision of the Terms of Service is found to be invalid or unenforceable, the remaining provisions will continue to be valid and enforceable to the fullest extent permitted by law.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      4.4 Retroactive Application
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      Revisions to the Terms of Service will not apply retroactively unless legally required or specifically stated. Any changes will apply prospectively, affecting your use of our services after the effective date of the modifications.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      4.5 User Responsibility
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      It is your responsibility to regularly review the Terms of Service for any changes and updates. By using our services, you agree to comply with the most recent version of the Terms of Service. If you do not agree with the updated terms, you should discontinue using our services.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt', lineHeight: '108%', fontSize: '14pt'}}>
                      <strong>&nbsp;</strong>
                    </p>
                    <h4>
                      5. User Behavior and Conduct Guidelines
                    </h4>
                    <p style={{marginBottom: '0pt', lineHeight: '108%', fontSize: '14pt'}}>
                      <strong>&nbsp;</strong>
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      5. User Behavior and Conduct Guidelines
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      Metaversity AI ("the Company," "we," or "our") is committed to maintaining a respectful and inclusive learning environment for all users. By accessing and using our services, you agree to adhere to the following user behavior and conduct guidelines. Failure to comply with these guidelines may result in disciplinary action, including account suspension or termination.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      5.1 Respectful and Inclusive Behavior
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      We expect all users to treat one another with respect, professionalism, and courtesy. You must refrain from engaging in any behavior that is discriminatory, offensive, or harassing. This includes, but is not limited to, behavior based on race, ethnicity, gender, sexual orientation, religion, disability, or any other protected characteristic.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      5.2 Academic Integrity and Honor Code
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      Maintaining academic integrity is essential to the learning experience at Metaversity AI. By accessing and using our services, you agree to uphold the principles of academic honesty and the Honor Code. This includes:
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      5.2.1 Originality of Work
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      You must submit your own original work and properly attribute any sources used. Plagiarism, cheating, or unauthorized collaboration with others is strictly prohibited.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      5.2.2 Collaboration and Collaboration Guidelines
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      Collaboration with other learners may be encouraged in certain courses. However, you must adhere to the specific collaboration guidelines outlined by the course instructors. Unauthorized collaboration beyond the allowed scope is not permitted.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      5.2.3 Exam and Assessment Integrity
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      During exams or assessments, you must not engage in any form of cheating, use unauthorized materials, or seek assistance from others unless explicitly permitted by the course instructors.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      5.2.4 Reporting Academic Integrity Violations
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      If you become aware of any violations of academic integrity by other users, it is your responsibility to report them promptly to Metaversity AI. We take such reports seriously and will investigate and take appropriate action.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      5.3 Professional Communication
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      Effective and professional communication is essential within our learning community. When interacting with instructors, mentors, or other users, you should:
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      - Use respectful and appropriate language.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      - Provide constructive feedback and engage in meaningful discussions.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      - Avoid personal attacks, harassment, or any form of disruptive behavior.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      5.4 Compliance with Laws and Regulations
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      When using our services, you must comply with all applicable laws, regulations, and intellectual property rights. You must not engage in any activity that is illegal, infringing, or violates the rights of others. This includes, but is not limited to, unauthorized sharing or distribution of copyrighted materials or engaging in any form of hacking or unauthorized access to our systems.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      5.5 User Content Guidelines
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      When creating or sharing content on our platform, you must adhere to the following guidelines:
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      - Your content must be relevant, accurate, and respectful.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      - You must not post or share any content that is offensive, harmful, or violates the rights of others.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      - You must not post or share any content that infringes upon intellectual property rights or violates any confidentiality or non-disclosure agreements.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      5.6 Reporting Violations
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      If you observe or experience any behavior that violates these user behavior and conduct guidelines, we encourage you to report it to Metaversity AI. We will investigate all reports and take appropriate action to address violations and maintain the integrity of our learning community.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt', lineHeight: '108%', fontSize: '14pt'}}>
                      <strong>&nbsp;</strong>
                    </p>
                    <h4>
                      6. Granting a License to Use the Services
                    </h4>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      Metaversity AI grants you a freely revocable, worldwide, non-exclusive, non-transferable, non-sublicensable limited right and license to access and use our services, subject to your compliance with these Terms of Use. This license is granted solely for your personal, non-commercial use and is subject to the following conditions:
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      6.1 Permitted Use
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      You may access and use our services for the purpose of participating in our online courses, accessing course materials, engaging in discussions, submitting assignments, and utilizing other features provided by Metaversity AI. You may not use our services for any other purpose unless expressly authorized by the Company.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      6.2 Intellectual Property Rights
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      All intellectual property rights related to our services, including but not limited to course materials, software, design elements, logos, trademarks, and copyrights, belong to Metaversity AI or our licensors. Except as expressly permitted in these Terms of Use, you may not reproduce, modify, distribute, display, perform, create derivative works from, or otherwise exploit any part of our services without prior written permission from Metaversity AI or the respective intellectual property owner.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      6.3 User-Generated Content
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      As a user of our services, you may have the opportunity to generate and contribute content, such as discussions, comments, project submissions, or forum posts. By submitting user-generated content, you grant Metaversity AI a non-exclusive, worldwide, royalty-free license to use, reproduce, distribute, display, and modify your content for the purpose of providing our services and enhancing the learning experience of our users.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      6.4 Prohibited Use
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      You must not use our services in any way that:
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      - Violates any applicable laws, regulations, or third-party rights.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      - Infringes upon the intellectual property rights of others.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      - Harms, interferes with, or disrupts the functioning of our services or the experiences of other users.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      - Attempts to gain unauthorized access to our systems or the personal information of other users.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      - Engages in any form of unauthorized commercial activities, including advertising, marketing, or solicitation without prior consent from Metaversity AI.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      6.5 Termination of License
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      Metaversity AI reserves the right to terminate or suspend your license to use our services at any time and for any reason, without prior notice. In such cases, you must immediately cease using our services and comply with any instructions or requests from Metaversity AI.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      6.6 Changes to the Services
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      Metaversity AI may modify, update, or discontinue any aspect of our services at any time and without prior notice. We are not liable to you or any third party for any modifications, interruptions, or discontinuation of our services.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      6.7 Reservation of Rights
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      All rights not expressly granted to you in these Terms of Use are reserved by Metaversity AI. We reserve the right to enforce our intellectual property rights to the fullest extent permitted by law.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt', lineHeight: '108%', fontSize: '14pt'}}>
                      <strong>&nbsp;</strong>
                    </p>
                    <h4>
                      7. License to Educational Content
                    </h4>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      Metaversity AI grants you a limited, non-exclusive, non-transferable license to access and use the educational content provided through our services, subject to your compliance with these Terms of Use. This license allows you to access and view the educational content for lifelong from the date of your enrollment or purchase, as applicable. The lifelong access provides learners with continuous updates derived from ongoing AI research, ensuring they stay up-to-date with the ever-evolving AI landscape. However, it is important to note that this lifelong license does not cover newly emerging branches resulting from the expansion of the field. In such cases, the license grants access solely to the main general course.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      7.1 Permitted Use
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      The educational content made available through our services is intended for your personal, non-commercial use and educational purposes. You may access and view the educational content, including but not limited to video lectures, presentations, readings, and other related materials, as part of your learning experience.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      7.2 Restrictions on Use
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      This license does not grant you the right to:
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      - Copy, reproduce, modify, distribute, display, or perform the educational content beyond what is necessary for your personal use and educational purposes.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      - Share, sell, rent, lease, sublicense, or otherwise transfer the educational content to any third party.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      - Use the educational content for any commercial or unauthorized purposes.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      - Download or capture the educational content, except as expressly permitted by the Company or provided functionality within our services.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      - Remove or modify any copyright, trademark, or other proprietary notices contained in the educational content.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      7.3 Ownership of Educational Content
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      All intellectual property rights related to the educational content provided through our services, including but not limited to text, images, audio, video, and interactive elements, belong to Metaversity AI or our educational partners. You acknowledge and agree that you have no ownership rights or interests in the educational content beyond the limited license granted under these Terms of Use.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      7.4 Duration of License
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      The license to access and view the educational content is granted for a period of three (3) years from the date of your enrollment or purchase, as applicable. After the expiration of this period, your access to the educational content may be terminated, and you may no longer have the ability to view or access the content through our services.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      7.5 Termination of Access
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      Metaversity AI reserves the right to terminate or suspend your access to the educational content at any time and for any reason, without prior notice. In such cases, you must immediately cease using the educational content and comply with any instructions or requests from Metaversity AI.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      7.6 No Modification or Creation of Derivative Works
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      You may not modify, adapt, translate, create derivative works from, or otherwise alter the educational content provided through our services. You must use the educational content as it is made available to you, without making any changes or modifications.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      7.7 Reservation of Rights
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      All rights not expressly granted to you in these Terms of Use are reserved by Metaversity AI. We reserve the right to enforce our intellectual property rights to the fullest extent permitted by law.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt', lineHeight: '108%', fontSize: '14pt'}}>
                      <strong>&nbsp;</strong>
                    </p>
                    <h4>
                      8. Intellectual Property
                    </h4>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      Metaversity AI respects intellectual property rights and expects users of our services to do the same. All intellectual property rights related to our services, including but not limited to course materials, software, design elements, logos, trademarks, and copyrights, belong to Metaversity AI or our licensors. These intellectual property rights are protected by New Zealand and international laws.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      8.1 Ownership of Intellectual Property
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      The intellectual property rights, including copyright, trademarks, and any other proprietary rights, in and to our services and the content provided therein are owned by Metaversity AI or our educational partners, where applicable. You acknowledge and agree that Metaversity AI and our licensors retain all rights, title, and interest in and to the intellectual property associated with our services.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      8.2 Use of Intellectual Property
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      You are granted a limited, non-exclusive, non-transferable license to access and use our services and the associated intellectual property solely for your personal, non-commercial use and in accordance with these Terms of Use. Except as expressly permitted in these Terms of Use or with the prior written consent of Metaversity AI, you may not reproduce, modify, distribute, display, perform, create derivative works from, or otherwise exploit any part of our services or the associated intellectual property.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      8.3 User Content
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      As a user of our services, you may have the opportunity to generate and contribute content, such as discussions, comments, project submissions, or forum posts. You retain ownership of any intellectual property rights that you hold in your user-generated content. However, by submitting user-generated content to our platform, you grant Metaversity AI a non-exclusive, worldwide, royalty-free license to use, reproduce, distribute, display, advertise, and modify your content for the purpose of providing our services and enhancing the learning experience of our users.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      8.4 Copyright Infringement
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      Metaversity AI respects the intellectual property rights of others and expects our users to do the same. If you believe that your copyrighted work has been copied and is accessible through our services in a way that constitutes copyright infringement, please refer to the "Procedure for Copyright Infringement Claims and Disputes" section of the Terms of Use for information on how to report copyright infringement.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      8.5 Reporting Intellectual Property Infringement
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      If you believe that any intellectual property rights have been infringed upon through the use of our services, please notify us promptly using the contact information provided in the "Procedure for Copyright Infringement Claims and Disputes" section of the Terms of Use. We will take appropriate action to address the reported infringement in accordance with applicable laws.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      8.6 Reservation of Rights
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      All rights not expressly granted to you in these Terms of Use are reserved by Metaversity AI. Nothing in these Terms of Use shall be construed as conferring any license or right to use any trademarks, logos, or service marks of Metaversity AI or our licensors without prior written permission.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt', lineHeight: '108%', fontSize: '14pt'}}>
                      <strong>&nbsp;</strong>
                    </p>
                    <h4>
                      9. Interactions in Discussion Forums, Chatrooms, and Assistance Chat
                    </h4>
                    <p style={{marginBottom: '0pt', lineHeight: '108%', fontSize: '14pt'}}>
                      <strong>&nbsp;</strong>
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      Metaversity AI provides discussion forums, chatrooms, and assistance chat features as part of our services to foster collaborative learning and provide support to our users. By participating in these interactive features, you agree to adhere to the following guidelines and responsibilities.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      9.1 Respectful and Constructive Communication
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      When engaging in discussions, chats, or seeking assistance, we expect all users to communicate in a respectful, professional, and constructive manner. You must refrain from engaging in any behavior that is offensive, discriminatory, or harassing. This includes, but is not limited to, using derogatory language, engaging in personal attacks, or making discriminatory remarks based on race, ethnicity, gender, sexual orientation, religion, disability, or any other protected characteristic.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      9.2 Relevant and Meaningful Contributions
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      To maintain a productive learning environment, you should make relevant and meaningful contributions in discussions, chatrooms, and assistance chat. Your contributions should relate to the topic at hand and contribute to the learning experience of other users. Spamming, excessive self-promotion, or irrelevant content is prohibited.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      9.3 Collaboration and Academic Honesty
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      In discussions, chatrooms, and assistance chat, you may collaborate with other users to enhance your learning experience. However, you must adhere to the collaboration guidelines provided by the course instructors or the specific guidelines outlined for the interactive feature. Engaging in unauthorized collaboration, sharing of answers, or any form of cheating is strictly prohibited.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      9.4 Confidentiality and Privacy
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      During discussions, chatrooms, and assistance chat, you may encounter personal or sensitive information shared by other users. You must respect the privacy and confidentiality of others and refrain from sharing or disclosing any personal information without the explicit consent of the individuals involved. Additionally, you should not share any confidential or proprietary information belonging to Metaversity AI or any third party.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      9.5 Reporting Violations
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      If you observe or experience any behavior that violates these interaction guidelines or the Terms of Use, we encourage you to report it to Metaversity AI. Please use the reporting mechanisms provided within our platform to notify us of any violations. We will investigate all reports and take appropriate action to address violations and maintain the integrity of our learning community.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      9.6 Moderation and Content Removal
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      Metaversity AI reserves the right to moderate and remove any content posted in discussions, chatrooms, or assistance chat that violates these guidelines or is otherwise deemed inappropriate. We may, at our discretion, edit, delete, or restrict access to content that violates our policies. However, we are not responsible for monitoring every interaction or guaranteeing the accuracy or reliability of user-generated content.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      9.7 Disclaimer of Responsibility
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      While Metaversity AI may provide assistance and support through chat features, we cannot guarantee immediate or continuous availability of assistance. Our instructors, mentors, or support staff may not be available at all times. Therefore, it is your responsibility to plan and manage your study time accordingly.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <h4>
                      10. Online Courses Provided by Educational Partners
                    </h4>
                    <p style={{marginBottom: '0pt', lineHeight: '108%', fontSize: '14pt'}}>
                      <strong>&nbsp;</strong>
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      Metaversity AI collaborates with educational partners to offer a wide range of courses and learning experiences through our platform. These courses are provided by our trusted educational partners and may be clearly indicated as such to users during the course selection process.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      10.1 Educational Partner Courses
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      Courses offered by our educational partners are designed and developed by them, utilizing their expertise and resources. These courses may cover various topics, disciplines, and levels of difficulty. While Metaversity AI facilitates the delivery and administration of these courses, the content, instructional methods, and assessment criteria are primarily determined by the educational partners.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      10.2 Course Information and Acknowledgment
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      When you select a course provided by an educational partner, Metaversity AI will provide relevant information about the course, including its title, description, learning objectives, and any specific terms or conditions associated with the course. You will be clearly informed that the course is offered by an educational partner.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      10.3 Responsibilities of Educational Partners
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      Our educational partners are responsible for ensuring the quality, accuracy, and relevance of the course content they provide. They are also responsible for addressing any inquiries, issues, or concerns related to their courses, including technical support, course-related questions, and grading or certification matters.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      10.4 User Agreement with Educational Partners
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      When you enroll in a course provided by an educational partner, you enter into a separate agreement with that educational partner. This agreement governs your rights and responsibilities as a student in their course. Metaversity AI is not a party to this agreement and is not responsible for any obligations or liabilities arising from your participation in educational partner courses.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      10.5 Disclaimer of Responsibility
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      Metaversity AI strives to collaborate with reputable educational partners to deliver high-quality courses. However, we do not guarantee the accuracy, completeness, or suitability of the course content provided by our educational partners. Metaversity AI is not responsible for any errors, omissions, or discrepancies in the content or delivery of educational partner courses. Any concerns, disputes, or issues related to educational partner courses should be addressed directly with the educational partner.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      10.6 Intellectual Property Rights
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      Intellectual property rights related to the courses provided by educational partners belong to the respective educational partners or their licensors. You agree to comply with the intellectual property rights and restrictions set forth by the educational partner, as communicated to you during the course.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      10.7 Termination or Modification of Educational Partner Courses
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      Metaversity AI and our educational partners strive to deliver the courses as advertised. However, circumstances may arise that require modification, suspension, or termination of a course. Metaversity AI and the educational partner reserve the right to make such changes, including changes to the course content, schedule, or availability, without prior notice. In such cases, Metaversity AI and the educational partner will make reasonable efforts to inform affected users and provide appropriate alternatives or remedies.
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      &nbsp;
                    </p>
                    <p style={{marginBottom: '0pt'}}>
                      Please refer to the remaining sections of the Terms of Use for additional information on your rights and responsibilities when using our services.
                    </p>
                    <p style={{marginBottom: '0pt', lineHeight: '108%', fontSize: '14pt'}}>
                      <strong>&nbsp;</strong>
                    </p>
                    <h4>
                      11. Enterprise Sponsored and Scholarship Supported Services
                    </h4>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      Metaversity AI offers enterprise-sponsored and scholarship-supported services to eligible individuals or organizations. These services provide opportunities for individuals or organizations to access our courses and learning resources through special arrangements made with our partners or sponsors.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      11.1 Enterprise-Sponsored Services
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      Enterprise-sponsored services are tailored to meet the specific needs and requirements of organizations that partner with Metaversity AI. These organizations may provide their employees or members with access to our courses, learning materials, and other resources as part of their professional development or training initiatives. The terms and conditions governing the use of enterprise-sponsored services may vary based on the agreements between Metaversity AI, the sponsoring organization, and the individuals participating in the program.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      11.2 Scholarship-Supported Services
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      Scholarship-supported services are designed to provide educational opportunities to individuals who may face financial limitations or have demonstrated exceptional merit. These services may be made available through partnerships with educational institutions, nonprofit organizations, or other entities that provide scholarships or funding to eligible individuals. The terms and conditions governing the use of scholarship-supported services may vary based on the specific scholarship program and the agreements between Metaversity AI, the sponsoring organization, and the scholarship recipients.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      11.3 Eligibility and Application Process
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      To access enterprise-sponsored or scholarship-supported services, individuals or organizations must meet the eligibility criteria established by the sponsoring organization or scholarship program. The eligibility requirements and application process may vary and will be clearly communicated by the sponsoring organization or scholarship program. Metaversity AI does not guarantee acceptance into any enterprise-sponsored or scholarship-supported services.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      11.4 Terms and Conditions
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      Individuals or organizations participating in enterprise-sponsored or scholarship-supported services are required to comply with all applicable terms and conditions set forth by Metaversity AI, the sponsoring organization, and any additional agreements or policies that may apply. These terms and conditions may include, but are not limited to, usage restrictions, limitations on the transferability of services, confidentiality obligations, and compliance with intellectual property rights.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      11.5 Modifications or Cancellations
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      Metaversity AI and the sponsoring organization or scholarship program reserve the right to modify or cancel the availability of enterprise-sponsored or scholarship-supported services at any time, with or without notice. In such cases, Metaversity AI will make reasonable efforts to notify affected individuals or organizations and provide appropriate alternatives or remedies, as applicable.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      11.6 Disclaimer of Responsibility
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      Metaversity AI is not responsible for any decisions or actions taken by the sponsoring organization or scholarship program, including but not limited to the selection of scholarship recipients or the provision of enterprise-sponsored services. Metaversity AI's role is limited to facilitating access to the services and resources made available through these arrangements.
                    </p>
                    <h4>
                      12. Payment Terms and Conditions
                    </h4>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      By using the services provided by Metaversity AI ("the Company," "we," or "our"), you agree to comply with the following payment terms and conditions. These terms outline the payment methods, fees, and other related provisions for accessing our courses and utilizing our services.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      12.1 Payment Obligations
                    </p>
                    <p>
                      When you enroll in a course or make a purchase through our platform, you agree to pay the applicable fees associated with the selected service. The fees will be clearly indicated to you during the checkout process or as otherwise specified on our website.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      12.2 Payment Methods
                    </p>
                    <p>
                      Metaversity AI accepts various payment methods, including but not limited to credit cards, debit cards, and other forms of electronic payment. The available payment methods may be subject to change, and you will be notified of the accepted payment methods during the payment process.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      12.3 Currency and Taxes
                    </p>
                    <p>
                      All fees and prices displayed on our platform are in the currency specified for your region or as indicated during the checkout process. You are responsible for any applicable taxes, including value-added taxes (VAT), goods and services taxes (GST), or other similar taxes imposed by your jurisdiction. The taxes, if applicable, will be added to the total amount due and displayed during the payment process.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      12.4 Payment Processing
                    </p>
                    <p>
                      When you make a payment, the transaction will be processed by a third-party payment processor designated by Metaversity AI. We take reasonable measures to ensure the security and confidentiality of your payment information. However, Metaversity AI is not responsible for any errors, delays, or security breaches that may occur during the payment processing by the third-party payment processor.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      12.5 Fees and Refunds
                    </p>
                    <p>
                      The fees associated with our services are non-refundable unless specified in our Cancellation and Refund Policy (refer to Section 13). Once a payment is made, it is your responsibility to ensure you have chosen the correct course or service before proceeding with the payment or during the trial period. By proceeding with the payment, you acknowledge and agree to the associated fees and terms.                    
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      12.6 Third-Party Payment Providers
                    </p>
                    <p>
                      In some cases, Metaversity AI may utilize third-party payment providers to facilitate payment transactions. When using third-party payment providers, you are subject to their terms and conditions and privacy policies. Metaversity AI is not responsible for any issues, disputes, or liabilities that may arise from your interactions with third-party payment providers.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      12.7 Payment Disputes
                    </p>
                    <p>
                      If you have any concerns or disputes regarding a payment transaction, please contact Metaversity AI's customer support using the contact information provided on our website. We will make reasonable efforts to address your concerns and resolve any payment-related issues in accordance with our policies and applicable laws.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      12.8 Changes to Fees
                    </p>
                    <p>
                      Metaversity AI reserves the right to modify or change the fees associated with our services at any time, with or without notice. Any changes to fees will not affect services for which you have already made a payment, unless otherwise specified by Metaversity AI.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <h4>
                      13. Cancellation and Refund Policy
                    </h4>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      At Metaversity AI, we strive to provide you with a positive learning experience. We understand that circumstances may change, and you may need to cancel your enrollment or request a refund for a service. This section outlines our cancellation and refund policy to ensure transparency and clarity.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      13.1 Cancellation
                    </p>
                    <p>
                      You have the right to cancel your enrollment in a program or terminate your subscription (if any) at any time. To cancel, please follow the instructions provided on our platform or contact our customer support. Upon cancellation, your access to the program material or service will be terminated, and you will no longer be charged any recurring fees.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      13.2 Refund Eligibility
                    </p>
                    <p>
                      Refunds may be available under specific circumstances as outlined in this policy. Please review the following conditions to determine your eligibility for a refund:
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      a) 10-Day Service Trial: For eligible services that offer a 10-day trial period, you may cancel your subscription within the trial period and request a refund. During the trial period, although you will have full access to all course features, please note that you may not have access to the complete course material for intellectual property security reasons.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      b) Technical Issues: If you encounter persistent technical issues that prevent you from accessing or using our services effectively, you may be eligible for a refund. We will assess each case individually to determine the appropriate resolution, which may include troubleshooting assistance or a refund, if necessary.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      c) Refunds take 5-10 days to appear on a customer's statement. Transaction fee for the original payment won't be returned, but there are no additional fee for the refund transaction.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      13.3 Refund Request Process
                    </p>
                    <p>
                      To request a refund, please submit a refund request through our platform or contact our customer support. Provide the necessary details, including your name, email address, order number, and a clear explanation of the reason for your refund request. We will review your request and respond to you within a reasonable timeframe.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      13.4 Refund Processing
                    </p>
                    <p>
                      If your refund request is approved, we will process the refund using the original payment method used for the purchase. Please note that it may take some time for the refunded amount to reflect in your account, depending on the policies of your financial institution.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      13.5 Non-Refundable Fees
                    </p>
                    <p>
                      Certain fees associated with our services are non-refundable unless otherwise stated in this policy or as required by applicable laws. These fees include, but are not limited to, transaction fees, administrative fees, and fees for services already rendered.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      13.6 Changes to the Refund Policy
                    </p>
                    <p>
                      Metaversity AI reserves the right to modify or change the refund policy at any time, with or without notice. Any changes to the refund policy will not affect refund requests submitted prior to the effective date of the policy change.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <h4>
                      14. Availability of Services and Limitations
                    </h4>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      Metaversity AI strives to provide a reliable and uninterrupted learning experience for our users. However, certain factors beyond our control may impact the availability and accessibility of our services. This section outlines the availability of our services and limitations that may apply.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      14.1 Service Availability
                    </p>
                    <p>
                      We make reasonable efforts to ensure that our services are available and accessible to users on a 24/7 basis, except for scheduled maintenance periods or unforeseen circumstances such as technical issues, force majeure events, or other events that may affect the normal operation of our platform. While we aim to minimize service disruptions, we cannot guarantee uninterrupted access to our services at all times.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      14.2 Scheduled Maintenance
                    </p>
                    <p>
                      To maintain and enhance the quality and performance of our platform, we may occasionally schedule maintenance activities. During these scheduled maintenance periods, certain services or features may be temporarily unavailable. We will make reasonable efforts to provide advance notice of any scheduled maintenance that may affect your access to our services.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      14.3 Technical Issues and Force Majeure Events
                    </p>
                    <p>
                      Despite our best efforts to provide a seamless user experience, technical issues or unforeseen events beyond our control may occasionally impact the availability of our services. These may include but are not limited to server outages, internet connectivity problems, hardware or software failures, natural disasters, or other force majeure events. In such cases, we will work diligently to resolve the issues and restore access to our services as soon as possible.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      14.4 Limitations and Compatibility
                    </p>
                    <p>
                      Our services may have certain limitations or requirements that you should be aware of. These may include:
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      a) Technical Requirements: To access our services, you are responsible for ensuring that you have the necessary hardware, software, internet connection, and compatible devices. We may provide minimum system requirements or recommendations for optimal performance, and it is your responsibility to meet these requirements.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      b) Content Availability: While we strive to offer a wide range of courses and learning materials, the availability of specific courses or content may vary over time. We may modify, update, or retire certain courses or content at our discretion. Additionally, the availability of courses provided by educational partners is subject to the agreements between Metaversity AI and the educational partners.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      c) Intellectual Property Rights: The intellectual property rights related to our services, including course materials, instructional content, and platform features, are protected by copyright and other applicable laws. You are granted a limited license to access and use the services as outlined in the Terms of Use (refer to Section 6), but you do not acquire any ownership rights to the intellectual property.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      14.5 Third-Party Services and Links
                    </p>
                    <p>
                      Our platform may contain links to third-party websites, applications, or services. We are not responsible for the availability, content, or practices of these third-party services. Any interactions or transactions you engage in with third-party services are solely between you and the respective third party, and their terms and policies will govern your use of those services.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      14.6 No Guarantees
                    </p>
                    <p>
                      While we strive to provide a high-quality and reliable learning experience, we do not guarantee specific outcomes, results, or improvements as a result of using our services. Your progress, achievements, and performance in our courses may vary based on individual effort, dedication, and other factors beyond our control.
                    </p>
                    <h4>
                      15. Disclaimer of Warranties
                    </h4>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      Metaversity AI provides services, including online courses, educational content, and platform features, on an "as is" and "as available" basis. By accessing or using our services, you acknowledge and agree to the following disclaimers of warranties:
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      15.1 No Warranty of Accuracy or Completeness
                    </p>
                    <p>
                      We strive to provide accurate and up-to-date information, content, and materials through our services. However, we do not warrant or guarantee the accuracy, completeness, or reliability of any information, content, or materials provided. The information and content offered through our services are for educational purposes only and should not be considered professional advice. You are solely responsible for evaluating the suitability, accuracy, and applicability of any information or content provided.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      15.2 No Warranty of Results or Outcomes
                    </p>
                    <p>
                      While we aim to provide high-quality educational experiences and resources, we do not guarantee specific results, improvements, or outcomes as a result of using our services. Your progress, achievements, and performance in our courses may vary based on individual effort, dedication, and other factors beyond our control. Any statements or examples of outcomes provided through our services are for illustrative purposes and do not guarantee similar results.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      15.3 No Warranty of Availability or Uninterrupted Access
                    </p>
                    <p>
                      We make reasonable efforts to ensure the availability and accessibility of our services. However, we do not warrant or guarantee that our services will be uninterrupted, error-free, or free from technical issues or other limitations. Factors beyond our control, such as internet connectivity, hardware or software failures, or force majeure events, may impact the availability and accessibility of our services. We are not liable for any loss or damage resulting from the unavailability or interruption of our services.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      15.4 No Warranty of Third-Party Services
                    </p>
                    <p>
                      Our platform may include links or references to third-party websites, applications, or services. We do not endorse, warrant, or guarantee the availability, accuracy, or quality of any third-party services. Any interactions or transactions you engage in with third-party services are solely between you and the respective third party, and their terms and policies will govern your use of those services.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      15.5 No Warranty of Security
                    </p>
                    <p>
                      While we take reasonable measures to ensure the security and confidentiality of your personal information and data, we cannot guarantee the absolute security of our systems or the transmission of data over the internet. You acknowledge and accept the inherent risks associated with online interactions and data transmission.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      15.6 No Other Warranties
                    </p>
                    <p>
                      To the fullest extent permitted by applicable laws, we expressly disclaim all warranties, whether express, implied, or statutory, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement. This disclaimer applies to the fullest extent permitted by law and shall survive any termination or expiration of the Terms of Use.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <h4>
                      16. Limitation of Liability
                    </h4>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      Please read this section carefully as it limits the liability of Metaversity AI and its officers, directors, employees, agents, affiliates, and partners. By accessing or using our services, you acknowledge and agree to the following terms regarding limitation of liability:
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      16.1 Exclusion of Consequential and Indirect Damages
                    </p>
                    <p>
                      To the fullest extent permitted by applicable laws, we shall not be liable for any indirect, incidental, consequential, special, or punitive damages, or any loss of profits or revenue, arising out of or in connection with your use of our services. This limitation applies regardless of the form of action, whether in contract, tort (including negligence), or otherwise, even if we have been advised of the possibility of such damages.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      16.2 Limitation of Direct Damages
                    </p>
                    <p>
                      To the fullest extent permitted by applicable laws, our total liability to you for any direct damages arising out of or in connection with your use of our services shall be limited to the amount you paid to us, if any, for the specific services giving rise to the claim or cause of action.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      16.3 Exceptions to Limitations
                    </p>
                    <p>
                      The limitations of liability set forth in this section shall not apply to:
                    </p>
                    <p>
                      a) Any liability arising from our intentional misconduct, fraud, or willful negligence;
                    </p>
                    <p>
                      b) Any liability that cannot be excluded or limited under applicable laws.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      16.4 Third-Party Claims
                    </p>
                    <p>
                      We are not responsible for any claims, demands, or actions brought against you by any third party arising out of or in connection with your use of our services. This includes, but is not limited to, claims related to intellectual property infringement, defamation, or violation of privacy rights. You agree to indemnify and hold us harmless from any such claims, demands, or actions.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      16.5 Force Majeure
                    </p>
                    <p>
                      We shall not be liable for any failure or delay in the performance of our obligations under the Terms of Use, including the provision of our services, to the extent that such failure or delay is caused by events beyond our reasonable control, including but not limited to natural disasters, acts of government, acts of terrorism, power outages, or internet connectivity issues.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      16.6 No Other Liability
                    </p>
                    <p>
                      To the fullest extent permitted by applicable laws, we disclaim any and all other liability, whether express or implied, arising out of or in connection with your use of our services.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <h4>
                      17. Indemnification
                    </h4>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      You agree to indemnify, defend, and hold harmless Metaversity AI, its officers, directors, employees, agents, affiliates, and partners from and against any and all claims, liabilities, damages, losses, costs, and expenses (including reasonable attorneys' fees) arising out of or in connection with:
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      17.1 Your Use of the Services
                    </p>
                    <p>
                      Your access to and use of our services, including but not limited to any violation of the Terms of Use, infringement of intellectual property rights, or breach of any applicable laws or regulations.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      17.2 User-Generated Content
                    </p>
                    <p>
                      Any content you submit, post, transmit, or otherwise make available through our services, including but not limited to user-generated content, comments, messages, and feedback. You are solely responsible for the content you share and the consequences of sharing it.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      17.3 Violation of Rights
                    </p>
                    <p>
                      Any claim or action alleging that your use of our services or the content you provide infringes or misappropriates the intellectual property rights, privacy rights, or other proprietary rights of any third party.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      17.4 Interactions with Third Parties
                    </p>
                    <p>
                      Any disputes, transactions, or interactions you have with other users or third parties, including but not limited to educational partners, sponsors, or scholarship providers. We are not responsible for the actions or conduct of any third party, and you agree to resolve any disputes directly with the relevant parties.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      17.5 Your Conduct
                    </p>
                    <p>
                      Any violation of the User Behavior and Conduct Guidelines outlined in these Terms of Use, including but not limited to actions that are unlawful, harmful, fraudulent, or violate the rights of others.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      17.6 Defense and Control of Claims
                    </p>
                    <p>
                      We reserve the right, at our own expense, to assume the exclusive defense and control of any matter subject to indemnification by you. You agree to cooperate with us in asserting any available defenses.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      17.7 Notice and Process
                    </p>
                    <p>
                      In the event of a claim or potential claim that may trigger your indemnification obligations, we will promptly notify you of such claim or potential claim. You agree to provide us with reasonable cooperation and assistance in the defense of any such claim.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      17.8 Survival
                    </p>
                    <p>
                      The indemnification obligations set forth in this section shall survive the termination or expiration of the Terms of Use.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <h4>
                      18. Termination of User Rights
                    </h4>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      18.1 Termination by Metaversity AI
                    </p>
                    <p>
                      Metaversity AI reserves the right to terminate or suspend your access to our services, in whole or in part, at any time and for any reason, without prior notice or liability. This includes, but is not limited to, situations where we believe you have violated the Terms of Use, engaged in fraudulent or illegal activities, or pose a risk to the security or integrity of our services or the rights of other users.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      18.2 Termination by User
                    </p>
                    <p>
                      You may terminate your use of our services at any time by discontinuing your access and ceasing to use our platform. However, please note that certain provisions of the Terms of Use, such as those relating to intellectual property rights, user-generated content, indemnification, and limitation of liability, shall continue to apply even after termination.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      18.3 Consequences of Termination
                    </p>
                    <p>
                      Upon termination of your user rights, whether by us or by you, you will no longer have access to the features and content available through our services. This includes any course materials, educational content, user-generated content, and communication channels. You understand and agree that we shall have no obligation to retain or provide any data or content associated with your account after termination.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      18.4 Survival of Rights and Obligations
                    </p>
                    <p>
                      The termination of your user rights does not affect the rights and obligations that are intended to survive termination. These include, but are not limited to, provisions related to intellectual property rights, user-generated content, indemnification, limitation of liability, and dispute resolution.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      18.5 No Liability for Termination
                    </p>
                    <p>
                      We shall not be liable to you or any third party for any termination of your user rights, regardless of the reason for such termination.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <h4>
                      19. Trade Regulations and Compliance
                    </h4>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      19.1 Compliance with Laws and Regulations
                    </p>
                    <p>
                      Metaversity AI is committed to conducting its business in compliance with all applicable laws, regulations, and trade requirements. By using our services, you agree to comply with all applicable local, national, and international laws and regulations that govern your access to and use of our platform.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      19.2 Export Control Laws
                    </p>
                    <p>
                      You acknowledge and agree that our services may be subject to export control laws and regulations of various countries. You agree not to export or re-export any part of our services in violation of such laws or regulations. You further acknowledge that you are solely responsible for complying with any applicable export control laws and obtaining any necessary licenses or permissions.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      19.3 Restricted Jurisdictions
                    </p>
                    <p>
                      Our services may be subject to restrictions and limitations in certain jurisdictions. It is your responsibility to ensure that your use of our services complies with the laws and regulations of your jurisdiction. We reserve the right to restrict or prohibit access to our services from any location or jurisdiction at our discretion.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      19.4 Intellectual Property Rights
                    </p>
                    <p>
                      You agree to respect and comply with all intellectual property rights associated with our services, including but not limited to copyrights, trademarks, patents, and trade secrets. You shall not engage in any activities that infringe upon or violate the intellectual property rights of others.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      19.5 Prohibited Conduct
                    </p>
                    <p>
                      You agree not to use our services for any unlawful, fraudulent, or unauthorized purposes. This includes, but is not limited to, engaging in activities that violate intellectual property rights, distributing unauthorized content, engaging in piracy or unauthorized copying, or engaging in any form of illegal or unethical behavior.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      19.6 Reporting Violations
                    </p>
                    <p>
                      If you become aware of any actual or potential violations of trade regulations or compliance obligations in connection with our services, please promptly report them to us. We will investigate and take appropriate actions to address the reported violations.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      19.7 No Liability
                    </p>
                    <p>
                      We shall not be held liable for any violations of trade regulations or compliance obligations by users of our services. Each user is solely responsible for their own compliance with applicable laws and regulations.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <h4>
                      20. Procedure for Copyright Infringement Claims and Disputes
                    </h4>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      20.1 Reporting Copyright Infringement
                    </p>
                    <p>
                      Metaversity AI ("the Company," "we," or "our") respects the intellectual property rights of others and expects our users to do the same. If you believe that your copyright-protected work has been used or copied in a way that constitutes copyright infringement, please promptly notify us in writing, including the following information:
                    </p>
                    <p>
                      - A physical or electronic signature of the copyright owner or a person authorized to act on their behalf.
                    </p>
                    <p>
                      - Identification of the copyrighted work claimed to have been infringed.
                    </p>
                    <p>
                      - Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material.
                    </p>
                    <p>
                      - Your contact information, including your name, address, telephone number, and email address.
                    </p>
                    <p>
                      - A statement that you have a good-faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law.
                    </p>
                    <p>
                      - A statement that the information in the notification is accurate, and under penalty of perjury, that you are authorized to act on behalf of the copyright owner.
                    </p>
                    <p>
                      Please send your copyright infringement notice to the following address:
                    </p>
                    <p style={{marginBottom: '0pt', lineHeight: '108%', fontSize: '10pt'}}>
                      Metaversity AI Ltd. 
                    </p>
                    <p style={{marginBottom: '0pt', lineHeight: '108%', fontSize: '10pt'}}>
                      1 Wagener Place, Mount Albert, Auckland 1025
                    </p>
                    <p style={{marginBottom: '0pt', lineHeight: '108%', fontSize: '10pt'}}>
                      <a href="/">info@metaversityai.ai</a>
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      20.2 Response to Copyright Infringement Claims
                    </p>
                    <p>
                      Upon receiving a valid copyright infringement notice, we will take appropriate actions, which may include removing or disabling access to the allegedly infringing material. We may also terminate the accounts of users who are determined to be repeat infringers.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      20.3 Counter-Notification
                    </p>
                    <p>
                      If you believe that your material has been removed or disabled as a result of a mistake or misidentification, you may submit a counter-notification to us. Your counter-notification must include the following information:
                    </p>
                    <p>
                      - Your physical or electronic signature.
                    </p>
                    <p>
                      - Identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or disabled.
                    </p>
                    <p>
                      - A statement under penalty of perjury that you have a good-faith belief that the material was removed or disabled as a result of mistake or misidentification.
                    </p>
                    <p>
                      - Your contact information, including your name, address, telephone number, and email address.
                    </p>
                    <p>
                     - A statement that you consent to the jurisdiction of the courts located within Auckland, New Zealand, and that you will accept service of process from the person who provided the original copyright infringement notification or an agent of such person.
                    </p>
                    <p>
                      Please send your counter-notification to the following address:
                    </p>
                    <p style={{marginBottom: '0pt', lineHeight: '108%', fontSize: '10pt'}}>
                      Metaversity AI Ltd. 
                    </p>
                    <p style={{marginBottom: '0pt', lineHeight: '108%', fontSize: '10pt'}}>
                      1 Wagener Place, Mount Albert, Auckland 1025
                    </p>
                    <p style={{marginBottom: '0pt', lineHeight: '108%', fontSize: '10pt'}}>
                      <a href="/">info@metaversityai.ai</a>
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      20.4 Disputes and Resolutions
                    </p>
                    <p>
                      In the event of any disputes arising from copyright infringement claims, both the claimant and the alleged infringer agree to comply with the relevant laws and procedures of New Zealand. Any legal actions or proceedings related to copyright infringement shall be conducted within the courts of New Zealand.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <h4>
                      21. Arbitration Clause
                    </h4>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      21.1. Any dispute, controversy, or claim arising out of or relating to these Terms of Use, including the formation, interpretation, breach, termination, or validity thereof, shall be resolved through arbitration.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      21.2. The arbitration shall be conducted in accordance with the rules of New Zealand International Arbitration Centre (NZIAC) as in effect at the time of initiating the arbitration. The arbitration shall be conducted by a single arbitrator appointed in accordance with those rules.
                    </p>
                    <p>
                      Address:
                    </p>
                    <p style={{marginBottom: '0pt', lineHeight: '108%', fontSize: '10pt'}}>
                      New Zealand International Arbitration Centre (NZIAC)
                    </p>
                    <p style={{marginBottom: '0pt', lineHeight: '108%', fontSize: '10pt'}}>
                      Level 15, The Todd Building, 95 Customhouse Quay, Wellington Central
                    </p>
                    <p style={{marginBottom: '0pt', lineHeight: '108%', fontSize: '10pt'}}>
                      <a>Wellington 6011, New Zealand</a>
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      21.3. The seat of arbitration shall be Auckland, New Zealand, and the language used in the arbitration proceedings shall be English. However, if both parties mutually agree, the arbitration can be conducted in another mutually agreed location.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      21.4. The decision or award of the arbitrator shall be final and binding upon the parties involved and may be enforced in any court of competent jurisdiction.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      21.5. Notwithstanding the above, either party may seek equitable relief from a court of competent jurisdiction to prevent or restrain the infringement of its intellectual property rights or to enforce restrictive covenants.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <h4>
                      22. Additional Provisions and Miscellaneous Clauses
                    </h4>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      22.1. Entire Agreement
                    </p>
                    <p>
                      These Terms of Use, including any referenced policies or agreements, constitute the entire agreement between Metaversity AI and the user and supersede all prior or contemporaneous understandings or agreements, whether oral or written.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      22.2. Governing Law 
                    </p>
                    <p>
                      These Terms of Use shall be governed by and construed in accordance with the laws of New Zealand, without regard to its conflict of laws principles.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      22.3. Severability
                    </p>
                    <p>
                      If any provision of these Terms of Use is held to be invalid or unenforceable, such provision shall be limited, modified, or severed to the minimum extent necessary to make it enforceable, and the remaining provisions shall continue in full force and effect.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      22.4. Waiver 
                    </p>
                    <p>
                      The failure of Metaversity AI to enforce any right or provision of these Terms of Use shall not constitute a waiver of such right or provision unless acknowledged and agreed to in writing by Metaversity AI.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      22.5. Assignment 
                    </p>
                    <p>
                      Users may not assign or transfer their rights or obligations under these Terms of Use without the prior written consent of Metaversity AI. Metaversity AI may assign or transfer its rights and obligations under these Terms of Use to a third party without user consent.
                    </p>
                    <p>
                      &nbsp;
                    </p>
                    <p>
                      22.6. Contact Information 
                    </p>
                    <p>
                      Any notices or communications required or permitted under these Terms of Use shall be sent to the contact information provided by the user during the account registration process.
                    </p>
                  </div>
    
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TermsCondition;
