import React from 'react';
import schoolsData from '../../../data/schoolsData.json';
import Dropdown from 'react-bootstrap/Dropdown';

function DynamicNavDropdown() {
  return (
    <Dropdown>
      <Dropdown.Toggle className="" id="dropdown-basic">
        Schools
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <div className='main-menu'>
        <ul className="submenu">
          {schoolsData.map(school => (
            <li key={school.id}>
              <Dropdown.Item href={`/schools${school.schoolLink}`} style={{ display: 'flex', justifyContent: 'space-between' }}>
                {school.schoolName}<i className="fal fa-chevron-right ps-2 align-self-center"></i>
              </Dropdown.Item>
              <ul className="submenu">
                {school.departments.map(department => (
                  <li key={department.id}>
                    {/* <Dropdown.Item href={department.departmentLink} style={{ display: 'flex', justifyContent: 'space-between' }}> */}
                    <Dropdown.Item href={`/schools${school.schoolLink}`} style={{ display: 'flex', justifyContent: 'space-between' }}>
                      Department of {department.department}<i className="fal fa-chevron-right ps-2 align-self-center "></i>
                    </Dropdown.Item>
                    <ul className="submenu">
                      {department.allprograms.map(program => (
                        <li key={program.id}>
                          <Dropdown.Item href={program.programLink}>{program.programTitle}</Dropdown.Item>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default DynamicNavDropdown;