import React, { lazy } from 'react';

const FundingBodySlider = lazy(() => import('../../Components/Elements/Slider/FundingBodySlider'))

const TeamTwo = () => {

    return (
        <section className="team-area pt-70 pb-90">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title-wrapper text-center mb-50">
                            <h5 className="section-subtitle mb-20">research support</h5>
                            <h2 className="section-title mb-35">Funding Organisations</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="team-active2 swiper-container">
                    <FundingBodySlider />
                </div>
            </div>
        </section>
    );
}

export default TeamTwo;