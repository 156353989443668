import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SchoolBanner from './SchoolBanner';
import SingleSchoolSection from './SingleSchoolSection';
import schoolsData from '../../data/schoolsData.json';

const SingleSchool = () => {
    const { schoolLink } = useParams();
    const [schoolDetails, setSchoolDetails] = useState(null);

    useEffect(() => {
        // Fetch the school details based on the schoolLink
        const school = schoolsData.find(s => s.schoolLink ===  `/${schoolLink}`);
        setSchoolDetails(school);
    }, [schoolLink]);

    if (!schoolDetails) {
        return <div>Loading...</div>;
    }


    return <main>
        {/* GenAIDepBanner-start */}
        <SchoolBanner school={schoolDetails}/>
        {/* GenAIDepBanner-end */}

        {/* Department-start */}
        <SingleSchoolSection school={schoolDetails}/>
        {/* Department-end */}

    </main>

}

export default SingleSchool;