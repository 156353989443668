import React from 'react';
import { Link } from 'react-router-dom';
import sideImg from '../../assets/img/blog/coverImg.jpg';

const SidebarPost = () => {

    return (
        <div className="widget_-latest-posts mb-30">
            <h4 className="bs-widget-title mb-25"> Spectra</h4>
            <div className="sidebar__widget-content">
                <div className="rc-post d-flex mb-15">
                    <div className="jai-cover-img">
                        <Link to="/"><a><img src={sideImg} alt="side-img"/></a></Link>
                    </div>
                    <div className="rc-text">
                        {/* <div className="kblog-meta">
                            <Link to="/"><a><i className="fal fa-user-circle"></i> By Admin</a></Link>
                        </div> */}
                    </div>
                </div>
            </div>
            <h5 className='mt-5 text-center text-primary'>
                <Link to="/admissions-office"><a>Call for Papers</a></Link>
            </h5>
            <h6 className='text-center'>in AI implementations in various domains of life</h6>
        </div>
    );
}

export default SidebarPost;