import React, { lazy } from 'react';
import DepBanner from './SchoolsBanner';
import SchoolsSection from '../Home/SchoolsSection';
import Quality from '../Home/QualitySection';
import ServiceFour from './ServiceSectionFour';
import SchoolsSectionThree from './SchoolsSectionThree';
import WhyWeThree from './WhyWeThree';
const BrandFour = lazy(() => import('../../Components/Elements/Brand/BrandSectionFour'))


const ServiceMain = () => {

    return (
        <main>
            {/* depbanner-start */}
            <DepBanner />
            {/* depbanner-end */}

            {/* service-start */}
            <div className="mt-50">
                <SchoolsSection />
            </div>
            {/* service-end */}

            {/* whywe-start */}
            {/* <WhyWeThree /> */}
            {/* whywe-end */}

            {/* service-start */}
            {/* <ServiceFour /> */}
            {/* service-end */}

            {/* brand-start */}
            {/* <BrandFour /> */}
            {/* brand-end */}

            {/* Quality-start */}
            <Quality />
            {/* Quality-end */}
        </main>
    );
}

export default ServiceMain;