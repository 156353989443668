import React from 'react';
import Breadcrumb from '../../Components/Elements/Element/Breadcrumb';
import ServiceDetails from './ServiceDetails';


const ServiceDetailsMain = () => {

    return <main>
        {/* breadcrumb-start */}
        <Breadcrumb pageTitle="Services Details" />
        {/* breadcrumb-end */}

        {/* service-start */}
        <ServiceDetails />
        {/* service-end */}

    </main>

}

export default ServiceDetailsMain;