import React from 'react';
import { Link } from 'react-router-dom';

const ArticleLayout = ({ post }) => {

  return (
        <div className="kblog">
            <div className="kblog-text">
                <div className="kblog-meta">
                    <Link href={post.url}><a><i className="fal fa-user-circle"></i> {post.user}</a></Link>
                    <Link href={post.url}><a><i className="fal fa-comments"></i> {post.comments}</a></Link>
                    <Link href={post.url}><a> {post.date}</a></Link>
                </div>
                <h3 className="paper-title mb-20">
                    <Link href={post.url}><a>{post.title}</a></Link>
                </h3>
                <p className="">
                    <a>{post.abstract}</a>
                </p>
                <div className="kblog-text-link">
                    <Link href={post.url}><a>{post.button} <i className="far fa-chevron-right"></i></a></Link>
                </div>
            </div>
        </div>
  );
}

export default ArticleLayout;